import React, {
    useState,
    SyntheticEvent,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { NotificationContext } from 'contexts/BroadCastContext';
import firebase from '../../utils/auth';
import { UserContext } from 'contexts/UserContext';
import './BroadCast.scss';
import { useSnackbar } from 'notistack';
import ModalPopup from 'components/Modal/Modal';
import { BroadCastType } from 'types';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

interface Column {
    id: 'message' | 'organizationId';
    label: string;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    fontWeight: 'bold';
    format?: (message: BroadCastType) => JSX.Element;
}

const columns: Column[] = [
    {
        id: 'message',
        label: 'Message',
        minWidth: 170,
        align: 'left',
        fontWeight: 'bold',
        format: (row: BroadCastType) => {
            return <Typography variant="inherit">{row.message}</Typography>;
        },
    },
    {
        id: 'organizationId',
        label: 'Date',
        minWidth: 170,
        align: 'center',
        fontWeight: 'bold',
        format: (row: BroadCastType) => {
            return (
                <Typography variant="inherit">
                    {dayjs(row.created_at.seconds * 1000).format('MM/DD/YYYY')}
                </Typography>
            );
        },
    },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

function BroadCast() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userData } = useContext(UserContext);
    const [orgMessages, setOrganizationMessages] = useState([]);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const { showNotification } = useContext(NotificationContext);
    const [broadcastMessage, setBroadcastMessage] = useState<string>('');

    const getOrgBroadcastMessages = useCallback(
        async (organizationId: string) => {
            const organizationBroadcastMessages =
                await firebase.getOrganizationBroadcastMessages(organizationId);
            setOrganizationMessages(organizationBroadcastMessages.payload);
        },
        [],
    );

    useEffect(() => {
        if (userData) {
            getOrgBroadcastMessages(userData?.organization_id);
        }
    }, [userData, getOrgBroadcastMessages]);

    const handlePost = async (e: SyntheticEvent) => {
        e.stopPropagation();
        try {
            // Code to add document to Firestore
            const volunteers =
                await firebase.getVolunteerProjectsByOrganization(
                    userData?.id!,
                );
            if (volunteers.status === 'ok' && volunteers.payload.length) {
                const response = await firebase.sendNotificationToVolunteers(
                    broadcastMessage,
                    userData?.organization_id!,
                    volunteers.payload as string[],
                );
                enqueueSnackbar(response?.payload.message, {
                    variant: 'success',
                    autoHideDuration: 2500,
                });
                showNotification(
                    `You have a new notification from your organization: ${userData?.company}`,
                    volunteers.payload as string[],
                );
                setBroadcastMessage('');
            } else {
                enqueueSnackbar(volunteers.payload, {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('An error occurred while broadcasting', {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Broadcast message"
                        multiline
                        fullWidth
                        value={broadcastMessage}
                        minRows={15}
                        variant="outlined"
                        onChange={(e) => setBroadcastMessage(e.target.value)}
                    />
                </Grid>
                <Grid container justifyContent="space-around">
                    <Grid item xs={4} md={6}>
                        <Button
                            className="g_primary_btn"
                            variant="contained"
                            color="secondary"
                            disabled={!broadcastMessage}
                            onClick={(e: SyntheticEvent) => handlePost(e)}
                        >
                            Post
                        </Button>
                    </Grid>

                    <Grid item xs={5} md={5}>
                        <Button
                            fullWidth
                            className="g_primary_btn"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                if (orgMessages.length) {
                                    setShowMessage(true);
                                } else {
                                    enqueueSnackbar(
                                        'No broadcast messages sent',
                                        {
                                            variant: 'warning',
                                            autoHideDuration: 2500,
                                        },
                                    );
                                }
                            }}
                        >
                            See Messages
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <ModalPopup
                fullWidth={true}
                isOpen={showMessage}
                handleClose={() => setShowMessage(false)}
            >
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            fontWeight: column.fontWeight,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderBy(orgMessages, ['created_at'], ['desc']).map(
                                (row: BroadCastType, index: number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            'string'
                                                            ? column.format(row)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ModalPopup>
        </>
    );
}

export default BroadCast;
