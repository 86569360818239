import { Box, CircularProgress, Typography } from '@material-ui/core';
import useAlgolia from 'hooks/useAlgolia';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { ProjectType, UserType } from '../../types';
import VolunteerSuggestionCard from './VolunteerSuggestionCard';

export interface ProjectsListProps {
    projectsData: Array<ProjectType>;
}

// const vdata: UserType[] = [
//   {
//     "id": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd",
//     "company": "Company B",
//     "email": "e3@email.com",
//     "first_name": "Martin",
//     "last_name": "Luther",
//     "projects": [],
//     "skills": [],
//     "timing": 2,
//     "work_type": 3,
//     "objectID": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//   },
//   {
//     "id": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2FBVk2XTpVE8Uyq3wwAIreUl2AzFf2.jpg?alt=media&token=27f8a950-e0e5-4dea-b630-4081ef9157ce",
//     "company": "Company A",
//     "email": "e1@email.com",
//     "first_name": "FName2",
//     "last_name": "LName",
//     "projects": [],
//     "skills": [
//       "Environment",
//       "Chemical industries",
//       "Education"
//     ],
//     "timing": 3,
//     "work_type": 3,
//     "objectID": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//   },
//   {
//     "id": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd",
//     "company": "Company B",
//     "email": "e3@email.com",
//     "first_name": "Martin",
//     "last_name": "Luther",
//     "projects": [],
//     "skills": [],
//     "timing": 2,
//     "work_type": 3,
//     "objectID": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//   },
//   {
//     "id": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2FBVk2XTpVE8Uyq3wwAIreUl2AzFf2.jpg?alt=media&token=27f8a950-e0e5-4dea-b630-4081ef9157ce",
//     "company": "Company A",
//     "email": "e1@email.com",
//     "first_name": "FName2",
//     "last_name": "LName",
//     "projects": [],
//     "skills": [
//       "Environment",
//       "Chemical industries",
//       "Education"
//     ],
//     "timing": 3,
//     "work_type": 3,
//     "objectID": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//   },
//   {
//     "id": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd",
//     "company": "Company B",
//     "email": "e3@email.com",
//     "first_name": "Martin",
//     "last_name": "Luther",
//     "projects": [],
//     "skills": [],
//     "timing": 2,
//     "work_type": 3,
//     "objectID": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//   },
//   {
//     "id": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2FBVk2XTpVE8Uyq3wwAIreUl2AzFf2.jpg?alt=media&token=27f8a950-e0e5-4dea-b630-4081ef9157ce",
//     "company": "Company A",
//     "email": "e1@email.com",
//     "first_name": "FName2",
//     "last_name": "LName",
//     "projects": [],
//     "skills": [
//       "Environment",
//       "Chemical industries",
//       "Education"
//     ],
//     "timing": 3,
//     "work_type": 3,
//     "objectID": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//   },
//   {
//     "id": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd",
//     "company": "Company B",
//     "email": "e3@email.com",
//     "first_name": "Martin",
//     "last_name": "Luther",
//     "projects": [],
//     "skills": [],
//     "timing": 2,
//     "work_type": 3,
//     "objectID": "MjOGM9MlkdfovmksNcSBbXPP3rx1",
//   },
//   {
//     "id": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//     "account_type": "Individual Partners",
//     "avatar": "https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/avatars%2FBVk2XTpVE8Uyq3wwAIreUl2AzFf2.jpg?alt=media&token=27f8a950-e0e5-4dea-b630-4081ef9157ce",
//     "company": "Company A",
//     "email": "e1@email.com",
//     "first_name": "FName2",
//     "last_name": "LName",
//     "projects": [],
//     "skills": [
//       "Environment",
//       "Chemical industries",
//       "Education"
//     ],
//     "timing": 3,
//     "work_type": 3,
//     "objectID": "BVk2XTpVE8Uyq3wwAIreUl2AzFf2",
//   },
// ]

const VolunteerSuggestion: FC<ProjectsListProps> = ({
    projectsData,
}: ProjectsListProps) => {
    const { usersIndex } = useAlgolia();
    const [isLoading, setIsLoading] = useState(true);
    const [suggestions, setSuggestions] = useState<UserType[]>([]);

    const fetchSuggestions = useCallback(async () => {
        if (projectsData) {
            setIsLoading(true);
            const projectSkills: string[] = [];
            for (let i = 0; i < projectsData.length; i += 1) {
                for (let j = 0; j < projectsData[i].categories.length; j += 1) {
                    if (
                        projectSkills.indexOf(projectsData[i].categories[j]) ===
                        -1
                    ) {
                        projectSkills.push(projectsData[i].categories[j]);
                    }
                }
            }
            const result = await usersIndex.search('', {
                filters: `${projectSkills
                    .map((skill) => `skills:"${skill}"`)
                    .join(' OR ')}`,
                hitsPerPage: 6,
            });
            setSuggestions(result.hits as any);
            setIsLoading(false);
        }
    }, [projectsData, usersIndex]);

    useEffect(() => {
        if (projectsData) {
            fetchSuggestions();
        }
    }, [projectsData, fetchSuggestions]);

    if (isLoading) {
        return (
            <Box
                width="100%"
                height="16rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <div className="g_row g_tspacer3 g_bspacer2 width_fixer">
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>Volunteer Suggestions</Box>
                </Typography>
            </div>
            <Box
                maxWidth="600px"
                m="auto"
                display="flex"
                flexWrap="wrap"
                gridGap="1rem"
            >
                {suggestions.length === 0 && (
                    <Typography>No suggestion found!</Typography>
                )}
                {suggestions.map((data) => (
                    <VolunteerSuggestionCard user={data} key={data.objectID} />
                ))}
            </Box>
        </div>
    );
};

export default VolunteerSuggestion;
