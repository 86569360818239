import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center vertically
        margin: 'auto',
        height: 'calc(100vh - 400px)',
        textAlign: 'center',
    },
}));
function Donation() {
    const styles = useStyles();
    return (
        <div className={styles.rootContainer}>
            <Typography variant="h4">
                This feature will be live soon. Stay tuned!
            </Typography>
        </div>
    );
}

export default Donation;
