import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { Edit, RestoreFromTrash } from '@material-ui/icons';
import EditPopup from './EditPopup';
import ProfilePictureUpdator from 'components/MyInformation/ProfilePictureUpdator';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { NetworkResponse, TeamMember } from 'types';
import ModalPopup from 'components/Modal/Modal';
import { nanoid } from 'nanoid';
import { UserContext } from 'contexts/UserContext';
import { isObjectEmpty } from 'helpers/utils';

interface AboutUsSectionProps {
    variant: 'private' | 'public';
    vision: string;
    overview: string;
    impact: string;
    mission: string;
    successStories: string;
    financials: string;
    ourTeam?: string;
    updateVision?: (vision: string) => void;
    updateOverview?: (overview: string) => void;
    updateImpact?: (impact: string) => void;
    updateMission?: (mission: string) => void;
    updateSuccessStories?: (successStories: string) => void;
    updateFinancials?: (financials: string) => void;
    updateOurTeam?: (ourTeam: string) => void;
    ourMembers?: TeamMember[];
    organizationId?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    rootDialog: {
        '& > .MuiBackdrop-root': { top: `calc(100vh - 390px) !important` },
    },
    impact: {
        fontWeight: 'bold',
    },
    labelMap: {
        fontWeight: 'bold',
    },
    projectOverview: {
        fontWeight: 'bold',
    },
    projectNeeds: {
        fontWeight: 'bold',
        marginTop: '30px',
    },
    backDrop: {
        filter: 'blur(3px)',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        fontSize: '1rem',
    },
    modalRoot: {
        flexGrow: 1,
        padding: 20,
    },
    profile: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    teamContainer: {
        padding: '10px 0',
    },
    teamMemberStyle: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center',
        border: '1px solid whitesmoke',
        borderRadius: '12px',
        cursor: 'pointer',
        margin: 5,
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    memberName: {
        fontWeight: 'bold',
        width: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
interface ParamTypes {
    id: string;
}
const initialValue = { id: '', avatar: '', name: '', position: '' };
const AboutUsSection = (props: AboutUsSectionProps) => {
    const classes = useStyles();
    const { id } = useParams<ParamTypes>();
    const { ourMembers = [], organizationId = '', ourTeam } = props;
    const DUMMY_URL =
        'https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png';
    const { enqueueSnackbar } = useSnackbar();
    const { userData, isLoggedIn } = useContext(UserContext);

    const [memberName, setMemberName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [memberAvatar, setMemberAvatar] = useState('');
    const [selectedMember, setSelectedMember] =
        useState<TeamMember>(initialValue);
    const [memberPosition, setMemberPosition] = useState('');
    const [teamMembers, setTeamMembers] = useState(ourMembers);
    const [isEditVisionOpen, setIsEditVisionOpen] = useState(false);
    const [isEditMissionOpen, setIsEditMissionOpen] = useState(false);
    const [isEditOverviewOpen, setIsEditOverviewOpen] = useState(false);
    const [isEditImpactOpen, setIsEditImpactOpen] = useState(false);
    const [isEditOurTeamOpen, setIsEditOurTeamsOpen] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [isEditFinancialsOpen, setIsEditFinancialsOpen] = useState(false);
    const [isEditSuccessStoriesOpen, setIsEditSuccessStoriesOpen] =
        useState(false);

    const resetStates = () => {
        setIsLoading(false);
        setShowAddMemberModal(false);
        setMemberName('');
        setMemberPosition('');
        setMemberAvatar('');
        setSelectedMember(initialValue);
    };

    const handleUpdateAvatar = async (file: File | null) => {
        setIsLoading(true);
        const member: TeamMember = {
            id: !!selectedMember.id ? selectedMember.id : nanoid(16),
            name: memberName,
            position: memberPosition,
            avatar: !!selectedMember.id ? selectedMember.avatar : '',
        };
        const response: NetworkResponse =
            await firebase.updateAvatarForOrganizationMember(
                file,
                organizationId,
                teamMembers?.length ? teamMembers : [],
                !!selectedMember.id,
                member,
            );

        if (response.status !== 200) {
            enqueueSnackbar('Network Issue!', {
                key: nanoid(16),
                variant: 'error',
            });
        } else {
            enqueueSnackbar(response.payload.message, {
                key: nanoid(16),
                variant: 'success',
            });
        }
        setTeamMembers(response.payload.teamMembers);
        resetStates();
    };

    const handleClose = () => {
        setShowAddMemberModal(false);
        resetStates();
    };

    const handleDeleteMember = async (member: TeamMember) => {
        const response: NetworkResponse =
            await firebase.deleteAvatarForOrganizationMember(
                member.avatar,
                organizationId,
                teamMembers?.length ? teamMembers : [],
                member,
            );

        if (response.status !== 200) {
            enqueueSnackbar('Network Issue!', {
                key: nanoid(16),
                variant: 'error',
            });
        } else {
            enqueueSnackbar(response.payload.message, {
                key: nanoid(16),
                variant: 'success',
            });
        }
        setTeamMembers(response.payload.teamMembers);
    };
    return (
        <div className={classes.root}>
            <>
                <EditPopup
                    isOpen={isEditVisionOpen}
                    initialValue={props.vision}
                    onClose={() => setIsEditVisionOpen(false)}
                    onUpdate={(value) =>
                        props.updateVision ? props.updateVision(value) : null
                    }
                />
                <EditPopup
                    isOpen={isEditImpactOpen}
                    initialValue={props.impact}
                    onClose={() => setIsEditImpactOpen(false)}
                    onUpdate={(value) =>
                        props.updateImpact ? props.updateImpact(value) : null
                    }
                />
                <EditPopup
                    isOpen={isEditOverviewOpen}
                    initialValue={props.overview}
                    onClose={() => setIsEditOverviewOpen(false)}
                    onUpdate={(value) =>
                        props.updateOverview
                            ? props.updateOverview(value)
                            : null
                    }
                />
                <EditPopup
                    isOpen={isEditMissionOpen}
                    initialValue={props.mission}
                    onClose={() => setIsEditMissionOpen(false)}
                    onUpdate={(value) =>
                        props.updateMission ? props.updateMission(value) : null
                    }
                />
                <EditPopup
                    isOpen={isEditSuccessStoriesOpen}
                    initialValue={props.successStories}
                    onClose={() => setIsEditSuccessStoriesOpen(false)}
                    onUpdate={(value) =>
                        props.updateSuccessStories
                            ? props.updateSuccessStories(value)
                            : null
                    }
                />
                <EditPopup
                    isOpen={isEditFinancialsOpen}
                    initialValue={props.financials}
                    onClose={() => setIsEditFinancialsOpen(false)}
                    onUpdate={(value) =>
                        props.updateFinancials
                            ? props.updateFinancials(value)
                            : null
                    }
                />
                {ourTeam && (
                    <EditPopup
                        isOpen={isEditOurTeamOpen}
                        initialValue={ourTeam}
                        onClose={() => setIsEditOurTeamsOpen(false)}
                        onUpdate={(value) =>
                            props.updateOurTeam
                                ? props.updateOurTeam(value)
                                : null
                        }
                    />
                )}
            </>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Box display={'flex'}>
                        <Typography
                            variant="h6"
                            className={classes.projectOverview}
                        >
                            Organization Overview
                        </Typography>
                        {props.variant === 'private' && (
                            <IconButton
                                size="small"
                                onClick={() => setIsEditOverviewOpen(true)}
                            >
                                <Edit className={classes.icon} />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Typography variant="subtitle1">
                            {props.overview}
                        </Typography>
                    </Box>
                </Grid>
                {/* <Grid item xs={4}>
                    <div className={classes.row}>
                        <Typography variant="h6" className={classes.impact}>
                            Vision
                        </Typography>
                        {props.variant === 'private' && (
                            <IconButton
                                size="small"
                                onClick={() => setIsEditVisionOpen(true)}
                            >
                                <Edit className={classes.icon} />
                            </IconButton>
                        )}
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Typography>{props.vision}</Typography>
                </Grid> */}
                <Grid item xs={6}>
                    <div className={classes.row}>
                        <Typography variant="h6" className={classes.impact}>
                            Mission and Goals
                        </Typography>
                        {props.variant === 'private' && (
                            <IconButton
                                size="small"
                                onClick={() => setIsEditMissionOpen(true)}
                            >
                                <Edit className={classes.icon} />
                            </IconButton>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.alignItemsCenter}>
                    <Typography>{props.mission}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.row}>
                        <Typography variant="h6" className={classes.impact}>
                            Success Stories
                        </Typography>
                        {props.variant === 'private' && (
                            <IconButton
                                size="small"
                                onClick={() =>
                                    setIsEditSuccessStoriesOpen(true)
                                }
                            >
                                <Edit className={classes.icon} />
                            </IconButton>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.alignItemsCenter}>
                    <Typography>{props.successStories}</Typography>
                </Grid>
                {ourTeam && (
                    <>
                        <Grid item xs={4}>
                            <div className={classes.row}>
                                <Typography>Meet Our Team</Typography>
                                {props.variant === 'private' && (
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            setIsEditOurTeamsOpen(true)
                                        }
                                    >
                                        <Edit className={classes.icon} />
                                    </IconButton>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{ourTeam}</Typography>
                        </Grid>
                    </>
                )}
                {/* <Grid item xs={6}>
                    <Box display={'flex'}>
                        <Typography variant="h6" className={classes.impact}>
                            Impact
                        </Typography>
                        {props.variant === 'private' && (
                            <IconButton
                                size="small"
                                onClick={() => setIsEditImpactOpen(true)}
                            >
                                <Edit className={classes.icon} />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>{props.impact}</Box>
                </Grid> */}
            </Grid>
            <Paper elevation={0} style={{ paddingTop: '40px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.impact}
                                >
                                    Meet our team
                                </Typography>
                            </Grid>
                            <Grid item>
                                {isLoggedIn &&
                                    (!id || id === userData?.organization_id) &&
                                    userData?.account_type ===
                                        'Organization' && (
                                        <Button
                                            disabled={teamMembers.length === 4}
                                            className="g_primary_btn"
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                                setShowAddMemberModal(true)
                                            }
                                        >
                                            Add Member
                                        </Button>
                                    )}
                            </Grid>
                        </Grid>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                className={classes.teamContainer}
                            >
                                {teamMembers?.length ? (
                                    teamMembers?.map((member) => {
                                        return (
                                            <Grid
                                                item
                                                container
                                                xs={3}
                                                key={member.avatar}
                                                className={
                                                    classes.teamMemberStyle
                                                }
                                            >
                                                <Avatar
                                                    className={classes.profile}
                                                    alt="Member Avatar"
                                                    src={member.avatar}
                                                />
                                                <Box>
                                                    <Tooltip
                                                        title={member.name}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            className={
                                                                classes.memberName
                                                            }
                                                        >
                                                            {member.name}
                                                        </Typography>
                                                    </Tooltip>
                                                    <Typography>
                                                        {member.position}
                                                    </Typography>
                                                </Box>
                                                {isLoggedIn &&
                                                    (!id ||
                                                        id ===
                                                            userData?.organization_id) &&
                                                    userData?.account_type ===
                                                        'Organization' && (
                                                        <div className="action_container">
                                                            <Tooltip title="Edit">
                                                                <IconButton
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        setSelectedMember(
                                                                            member,
                                                                        );
                                                                        setMemberName(
                                                                            member.name,
                                                                        );
                                                                        setMemberPosition(
                                                                            member.position,
                                                                        );
                                                                        setMemberAvatar(
                                                                            member.avatar,
                                                                        );
                                                                        setShowAddMemberModal(
                                                                            true,
                                                                        );
                                                                    }}
                                                                >
                                                                    <Edit fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handleDeleteMember(
                                                                            member,
                                                                        );
                                                                    }}
                                                                >
                                                                    <RestoreFromTrash color="error" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.impact}
                                    >
                                        No team members are added yet.
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Typography
                            variant="h6"
                            className={classes.projectNeeds}
                        >
                            Project Needs
                        </Typography>
                        <Box>
                            <ul>
                                <li>
                                    Volunteers with experience working with
                                    children ages 4-14.
                                </li>
                                <li>Girls' & Women's empowerment.</li>
                                <li>Soccer coaches</li>
                            </ul>
                        </Box> */}
                    </Grid>
                </Grid>
            </Paper>
            <ModalPopup
                maxWidth={'md'}
                isOpen={showAddMemberModal}
                handleClose={handleClose}
            >
                <div className={classes.modalRoot}>
                    {/* {isLoading ? (
                        <CircularProgress />
                    ) : ( */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                className={classes.impact}
                            >
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                value={memberName}
                                onChange={(e) => setMemberName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                className={classes.impact}
                            >
                                Position
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="row_centered">
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    className="form-control"
                                >
                                    <TextField
                                        variant="outlined"
                                        value={memberPosition}
                                        onChange={(e) =>
                                            setMemberPosition(e.target.value)
                                        }
                                        fullWidth
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                className={classes.impact}
                            >
                                Upload Picture
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ProfilePictureUpdator
                                img={memberAvatar || DUMMY_URL}
                                handleUpdateAvatar={(file: File) => {
                                    handleUpdateAvatar(file);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider orientation="horizontal" />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleUpdateAvatar(null)}
                                    disabled={!(memberName && memberPosition)}
                                >
                                    {!isObjectEmpty(selectedMember as any)
                                        ? 'Update'
                                        : 'Add'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* )} */}
                </div>
            </ModalPopup>
        </div>
    );
};

export default AboutUsSection;
