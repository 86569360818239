import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Chip, InputBase, alpha, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CrossIcon from '@material-ui/icons/Close';
import algoliasearch from 'algoliasearch';
import { Link } from 'react-router-dom';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from 'const';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
const useStyles = makeStyles((theme) => ({
    resultContainer: {
        position: 'absolute',
        maxHeight: 300,
        backgroundColor: alpha(theme.palette.common.white, 1),
        zIndex: 9999,
        width: 'calc(100% + 58px)',
        left: -29,
        marginTop: 10,
        boxShadow:
            'rgba(9, 30, 66, 0.25) 0px 4px 8px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
        borderRadius: 4,
        overflow: 'auto',
    },
    searchContainer: {
        backgroundColor: alpha(theme.palette.common.white, 1),
        marginRight: 20,
        borderRadius: 4,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        color: 'black',
        paddingTop: 5,
        paddingLeft: 5,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 95,
        'z-index': 10,
        top: 3,
    },
    crossIcon: {
        color: 'black',
        paddingTop: 5,
        paddingRight: 5,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 95,
        'z-index': 10,
        top: 3,
    },
    inputRoot: {
        color: 'black',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        width: '100%',
        [theme.breakpoints.down('xl')]: {
            width: '45ch',
        },
        [theme.breakpoints.down('lg')]: {
            width: '45ch',
        },
        [theme.breakpoints.down('md')]: {
            width: '50ch',
        },
        [theme.breakpoints.down('sm')]: {
            width: '40ch',
        },
        [theme.breakpoints.down('xs')]: {
            width: '27ch',
        },
    },
    highlighted: {
        backgroundColor: '#eeeeee',
        transition: 'ease-in 200ms',
        '& button': {
            opacity: 1,
            transition: 'ease-in 200ms',
        },
        padding: theme.spacing(1),
    },
    hr: {
        border: '0.1px solid #EEEEEE',
        width: '100%',
    },
    resultIndex: {
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
        '&:hover ': {
            cursor: 'pointer',
            backgroundColor: '#eeeeee',
            transition: 'ease-in 200ms',
            '& button': {
                opacity: 1,
                transition: 'ease-in 200ms',
            },
        },
    },
}));

type SearchProps = {};
const Search: FC<SearchProps> = (props: SearchProps) => {
    const searchInput = useRef<HTMLInputElement>();
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [searchedResults, setSearchedResults] = useState<any>([]);
    // const [_showSearchBar, setShowSearchBar] = useState<boolean>(false);
    const [showSearchResults, setShowSearchResults] = useState<boolean>(false);

    const handleSearch = useCallback((value: string) => {
        const queries = [
            {
                indexName: 'organizations',
                query: value,
                params: {
                    hitsPerPage: 3,
                    attributesToRetrieve: ['company'],
                },
            },
            {
                indexName: 'projects',
                query: value,
                params: {
                    hitsPerPage: 3,
                    attributesToRetrieve: ['name'],
                },
            },
        ];

        // perform 3 queries in a single API call:
        //  - 1st query targets index `organization`
        //  - 2nd query target index `projects`
        searchClient.search(queries).then(({ results }:any) => {
            const result = results.map((item: { hits: any; index: any; }) => {
                return { hits: item.hits, index: item.index };
            });
            const formattedResult = result[0].hits.concat(result[1].hits);
            setSearchedResults(formattedResult);
        });
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                handleSearch(searchTerm);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm, handleSearch]);

    const handleHighlightedIndex = (keyCode: number) => {
        const resultCount = searchedResults.length;
        switch (keyCode) {
            case 13:
                break;
            case 38:
                if (highlightedIndex > 0) {
                    setHighlightedIndex(highlightedIndex - 1);
                } else {
                    setHighlightedIndex(resultCount - 1);
                }
                break;
            case 40:
                if (highlightedIndex < resultCount - 1) {
                    setHighlightedIndex(highlightedIndex + 1);
                } else {
                    setHighlightedIndex(0);
                }
                break;

            default:
                break;
        }
    };

    return (
        <div className="">
            <Box
                className={classes.searchContainer}
                display="flex"
                justifyContent="space-between"
                aria-controls="license-search-menu"
                aria-haspopup="true"
            >
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <div className={classes.search}>
                    <InputBase
                        placeholder="Search by organization or project"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onKeyDown={(e) => {
                            handleHighlightedIndex(e.keyCode);
                        }}
                        onFocus={() => {
                            setTimeout(() => {
                                setShowSearchResults(true);
                            }, 110);
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                // setShowSearchBar(false);
                            }
                            setTimeout(() => {
                                setShowSearchResults(false);
                            }, 300);
                        }}
                        value={searchTerm}
                        onChange={(e) => {
                            if (e.target.value.trim().length > 2) {
                                setSearchTerm(
                                    e.target.value
                                        .trim()
                                        .split(/[&#%]/)
                                        .join(''),
                                );
                                setShowSearchResults(true);
                            }
                            setSearchTerm(e.target.value);
                        }}
                        inputRef={searchInput}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    {showSearchResults &&
                    searchedResults.length &&
                    searchTerm.trim().length > 2 ? (
                        <Box className={classes.resultContainer}>
                            {searchedResults.map((item: any, index: number) => {
                                const key = Object.keys(item)[0];

                                return (
                                    <div key={item.objectID}>
                                        {index !== 0 && (
                                            <div className={classes.hr}></div>
                                        )}
                                        <Link
                                            className={[
                                                classes.resultIndex,
                                                highlightedIndex === index
                                                    ? classes.highlighted
                                                    : '',
                                            ].join(' ')}
                                            to={
                                                key === 'company'
                                                    ? `/organization/${item.objectID}`
                                                    : `${item.objectID}/project-details`
                                            }
                                        >
                                            <div>{item[key]}</div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    color={
                                                        key === 'company'
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    size="small"
                                                    label={
                                                        key === 'company'
                                                            ? 'Organization'
                                                            : 'Project'
                                                    }
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </Box>
                    ) : (
                        <></>
                    )}
                </div>
                {searchTerm.trim().length > 0 && (
                    <div
                        className={classes.crossIcon}
                        onClick={() => {
                            setSearchTerm('');
                            searchInput?.current?.focus();
                        }}
                    >
                        <CrossIcon />
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Search;
