import React from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjsUtils from '@date-io/dayjs';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NotificationProvider from 'contexts/BroadCastContext';
import UserContextProvider from 'contexts/UserContext';
import ApproverProvider from 'contexts/ApprovalContext';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <MuiPickersUtilsProvider utils={dayjsUtils}>
                <SnackbarProvider
                    maxSnack={10}
                    autoHideDuration={3000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <UserContextProvider>
                        <NotificationProvider>
                            <ApproverProvider>
                                <App />
                            </ApproverProvider>
                        </NotificationProvider>
                    </UserContextProvider>
                </SnackbarProvider>
            </MuiPickersUtilsProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((_registration) => {
                // console.log('Service worker registered:', registration);
            })
            .catch((err) => {
                console.log('Service worker registration failed:', err);
                throw err;
            });
    });
}
