import { Button, Dialog, makeStyles, TextField } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

interface EditPopupProps {
    isOpen: boolean;
    initialValue: string;
    onUpdate: (a: string) => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        // width: '100%',
        background: 'white',
        padding: '1rem',
        boxSizing: 'border-box',
        margin: '1rem auto',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '2rem',
    },
}));

const EditPopup = (props: EditPopupProps) => {
    const classes = useStyles();

    const [value, setValue] = useState(props.initialValue);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <div className={classes.root}>
                <TextField
                    value={value}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setValue(e.target.value);
                    }}
                />
                <div className={classes.buttons}>
                    <Button onClick={props.onClose} fullWidth>
                        close
                    </Button>
                    <Button
                        onClick={() => {
                            props.onUpdate(value);
                            props.onClose();
                        }}
                        color="primary"
                        variant="contained"
                        fullWidth
                    >
                        update
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditPopup;
