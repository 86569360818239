import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import logo from '../../images/N-Vest-Logo.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../App';
import './header.scss';
import { UserContext } from '../../contexts/UserContext';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
    Backdrop,
    CircularProgress,
    IconButton,
    Container,
} from '@material-ui/core';
import { CommonDataContext } from '../../contexts/CommonDataContext';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MenuIcon from '@material-ui/icons/Menu';
import ProfilePicture from '../ProfilePicture/ProfilePicture';

interface HeaderProps {
    showAvatar?: boolean;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const history = useHistory();
    const { mobileViewBig } = useContext(CommonDataContext);
    const { isLoggedIn, isChecking, logout } = useContext(UserContext);
    const [openDrawer, setOpenDrawer] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Container maxWidth="lg">
            <div className="header-component">
                <Backdrop open={isChecking} style={{ zIndex: 9999999 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div
                    className="header-component__logo"
                    onClick={() => history.push('/')}
                >
                    <div>
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className="header_right_side">
                    {mobileViewBig ? (
                        <>
                            <IconButton
                                onClick={() => setOpenDrawer(true)}
                                aria-label="delete"
                                className="header_sidebar_btn"
                                size="medium"
                            >
                                <MenuIcon fontSize="inherit" />
                            </IconButton>

                            <SwipeableDrawer
                                anchor="right"
                                open={openDrawer}
                                onClose={() => setOpenDrawer(false)}
                                onOpen={() => setOpenDrawer(true)}
                            >
                                <div onClick={() => setOpenDrawer(false)}>
                                    <div className="header_drawer_links">
                                        <div className="header_sidebar_avatar">
                                            <ProfilePicture size="headerMobile" />
                                        </div>
                                        {/* {isLoggedIn &&
                                            !isChecking &&
                                            accountType &&
                                            ACCOUNT_TYPES.indexOf(accountType) ===
                                                3 && (
                                                <div>
                                                    <Link
                                                        to={
                                                            userData
                                                                ? '/organization/' +
                                                                userData.organization_id
                                                                : ''
                                                        }
                                                    >
                                                        <Button
                                                            fullWidth
                                                            // variant="outlined"
                                                            className="header-component__header-actions"
                                                        >
                                                            Home LORA
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )} */}
                                        {isLoggedIn && !isChecking && (
                                            <div>
                                                <Link to={AppRoutes.Profile}>
                                                    <Button
                                                        fullWidth
                                                        // variant="outlined"
                                                        className="header-component__header-actions"
                                                    >
                                                        Profile
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                        {!isLoggedIn && !isChecking && (
                                            <div>
                                                <Link
                                                    to={
                                                        AppRoutes.HowWorksOrganization
                                                    }
                                                >
                                                    <Button
                                                        fullWidth
                                                        // variant="outlined"
                                                        className="header-component__header-actions"
                                                    >
                                                        Organizations
                                                    </Button>
                                                </Link>

                                                <Link
                                                    to={
                                                        AppRoutes.HowWorksVolunteer
                                                    }
                                                >
                                                    <Button
                                                        fullWidth
                                                        // variant="outlined"
                                                        className="header-component__header-actions"
                                                    >
                                                        Partners
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}

                                        {isLoggedIn && !isChecking && (
                                            <div>
                                                <Link
                                                    to={
                                                        AppRoutes.HowWorksOrganization
                                                    }
                                                >
                                                    <Button
                                                        fullWidth
                                                        // variant="outlined"
                                                        className="header-component__header-actions"
                                                    >
                                                        Organizations
                                                    </Button>
                                                </Link>
                                                <Link
                                                    to={
                                                        AppRoutes.HowWorksVolunteer
                                                    }
                                                >
                                                    <Button
                                                        fullWidth
                                                        // variant="outlined"
                                                        className="header-component__header-actions"
                                                    >
                                                        Partners
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                        <Link to={AppRoutes.AboutNvest}>
                                            <Button
                                                fullWidth
                                                // variant="outlined"
                                                className="header-component__header-actions"
                                            >
                                                About Us
                                            </Button>
                                        </Link>

                                        {isLoggedIn && !isChecking && (
                                            <div>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={logout}
                                                >
                                                    Logout
                                                </Button>
                                            </div>
                                        )}
                                        {!isLoggedIn && !isChecking && (
                                            <div>
                                                <Link
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                    to={AppRoutes.Login}
                                                >
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="secondary"
                                                        className="g_primary_btn"
                                                    >
                                                        Login
                                                    </Button>
                                                </Link>
                                                <Link
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                    to={AppRoutes.Signup}
                                                >
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        // className="header-component__header-actions"
                                                    >
                                                        Sign-Up
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwipeableDrawer>
                        </>
                    ) : (
                        <>
                            <div className="header-component__header-links">
                                {isLoggedIn && !isChecking ? (
                                    <>
                                        <Link
                                            to={AppRoutes.HowWorksOrganization}
                                        >
                                            Organizations
                                        </Link>
                                        <Link to={AppRoutes.HowWorksVolunteer}>
                                            Partners
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        {!isChecking && (
                                            <>
                                                <div>
                                                    <Link
                                                        to={
                                                            AppRoutes.HowWorksOrganization
                                                        }
                                                    >
                                                        Organizations
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={
                                                            AppRoutes.HowWorksVolunteer
                                                        }
                                                    >
                                                        Partners
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                <Button
                                    href="#"
                                    aria-controls="about-us-menu"
                                    aria-haspopup="true"
                                    onClick={handleClickMenu}
                                    className="nav-dropdown"
                                >
                                    About Us
                                </Button>
                                <Menu
                                    id="about-us-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={handleCloseMenu}>
                                        <Link to={AppRoutes.AboutNvest}>
                                            About N-Vest Partners
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <Link to={AppRoutes.FounderStory}>
                                            Founder's Story
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <Link to={AppRoutes.OurTeam}>
                                            Our Team
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </div>
                            <Link
                                className="footer_donation"
                                to={AppRoutes.donation}
                            >
                                <Button
                                    className="g_primary_btn"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Donate
                                </Button>
                            </Link>
                            <div className="header-component__header-actions">
                                {isLoggedIn ? (
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={logout}
                                        >
                                            Logout
                                        </Button>
                                        {props.showAvatar && (
                                            <Link to={AppRoutes.Profile}>
                                                <div className="header_avatar">
                                                    <ProfilePicture size="header" />
                                                </div>
                                            </Link>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Link to={AppRoutes.Login}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className="g_primary_btn"
                                            >
                                                Login
                                            </Button>
                                        </Link>
                                        <Link to={AppRoutes.Signup}>
                                            <Button
                                                variant="outlined"
                                                className="header-component__header-actions signupbtn"
                                            >
                                                Sign-Up
                                            </Button>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};
export default Header;
