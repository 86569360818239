import { Box, Typography } from '@material-ui/core';
import ProjectCard from 'components/ProjectCard/ProjectCard';
import { ProjectsContext } from 'contexts/ProjectsContext';
import React, { useContext } from 'react';
import { ProjectType } from 'types';

export default function ProjectsList() {
    const { projects, isLoading } = useContext(ProjectsContext);

    return (
        <Box mt="2rem">
            {!isLoading && projects.length === 0 && (
                <Typography>
                    You currently are not part of any volunteer projects. Join a
                    project to get started!
                </Typography>
            )}
            {!isLoading &&
                projects.map((project: ProjectType) => (
                    <ProjectCard
                        key={project.objectID}
                        projectId={project.id}
                        heading={project.name}
                        subHeading={project.causes.join(', ')}
                        groupName={project.group_name}
                        startTime={project.schedule_start}
                        endTime={project.schedule_end}
                        duration={project.activity_duration_per_week}
                        volunteerLocation={project.activity_type}
                        teamHeadline={'Team members!'}
                        team={project.team_members}
                        isSuggestion={false}
                    />
                ))}
        </Box>
    );
}
