import React, { createContext, useContext, useEffect, useState } from 'react';
import { ChatType } from 'types';
import firebase from '../utils/auth';
import { UserContext } from './UserContext';

export const MessageContext = createContext<any>(null);

const MessageContextProvider = (props: any) => {
    const { isLoggedIn } = useContext(UserContext);

    const [chats, setChats] = useState<Array<ChatType>>([]);

    const [totalUnreadChats, setTotalUnreadChats] = useState<number>();

    useEffect(() => {
        let unsub: any = () => {
            return;
        };

        if (isLoggedIn) {
            unsub = setUpChatListener();
        }

        return unsub;
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            let total = 0;
            chats.map((chat) => {
                let index = chat.participants.indexOf(
                    firebase.auth.currentUser?.uid ?? '',
                );
                if (index >= 0 && chat.last_reply !== chat.last_read[index]) {
                    total += 1;
                }
                return total;
            });
            setTotalUnreadChats(total);
        }
    }, [chats, isLoggedIn]);

    const setUpChatListener = () => {
        let unsub: any = null;

        if (firebase.auth.currentUser) {
            unsub = firebase.db
                .collection('chat')
                .where(
                    'participants',
                    'array-contains',
                    firebase.auth.currentUser.uid,
                )
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        let docData = change.doc.data();
                        docData.created_at = docData.created_at
                            ? docData.created_at.toDate()
                            : new Date().toISOString();
                        docData.updated_at = docData.updated_at
                            ? docData.updated_at.toDate()
                            : new Date().toISOString();
                        let participantIndex = docData.participants.indexOf(
                            firebase.auth.currentUser?.uid as string,
                        );

                        if (participantIndex >= 0 && change.type === 'added') {
                            if (
                                docData.deleted_until_id[participantIndex] !==
                                docData.last_reply
                            ) {
                                setChats((pre) => {
                                    let temp = [...pre];
                                    temp.push({
                                        ...docData,
                                        id: change.doc.id,
                                    } as ChatType);
                                    return temp;
                                });
                            }
                        }
                        if (
                            participantIndex >= 0 &&
                            change.type === 'modified'
                        ) {
                            if (
                                docData.deleted_until_id[participantIndex] !==
                                docData.last_reply
                            ) {
                                setChats((pre) => {
                                    let temp = [];
                                    for (let i = 0; i < pre.length; i++) {
                                        if (pre[i].id === change.doc.id) {
                                            temp.push({
                                                ...change.doc.data(),
                                                id: change.doc.id,
                                            } as ChatType);
                                        } else {
                                            temp.push(pre[i]);
                                        }
                                    }
                                    return temp;
                                });
                            } else {
                                // removing
                                setChats((pre) => {
                                    let temp = [];
                                    for (let i = 0; i < pre.length; i++) {
                                        if (pre[i].id === change.doc.id) {
                                            // do nothing
                                        } else {
                                            temp.push(pre[i]);
                                        }
                                    }
                                    return temp;
                                });
                            }
                        }
                        if (change.type === 'removed') {
                            // removing
                            setChats((pre) => {
                                let temp = [];
                                for (let i = 0; i < pre.length; i++) {
                                    if (pre[i].id === change.doc.id) {
                                        // do nothing
                                    } else {
                                        temp.push(pre[i]);
                                    }
                                }
                                return temp;
                            });
                        }
                    });
                });
        }
        return unsub;
    };

    return (
        <MessageContext.Provider value={{ chats, totalUnreadChats, setChats }}>
            {props.children}
        </MessageContext.Provider>
    );
};

export default MessageContextProvider;
