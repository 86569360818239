import React, { createContext, useCallback, useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { useEffect } from 'react';
import firebase from '../utils/auth';
import { useSnackbar } from 'notistack';
import { BroadCastType } from 'types';

export type NotificationContextType = {
    showNotification: (message: string, volunteerIds: string[]) => void;
    notifications: Array<BroadCastType>;
    totalNotifications: number;
};

export const NotificationContext = createContext<NotificationContextType>({
    showNotification: () => {},
    notifications: [],
    totalNotifications: 0,
});

type NotificationProviderProps = {
    children: React.ReactNode;
};

const NotificationProvider = ({ children }: NotificationProviderProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoggedIn, userData } = useContext(UserContext);
    const [totalNotifications, setTotalNotifications] = useState<number>(0);
    const [notifications, setNotifications] = useState<Array<BroadCastType>>(
        [],
    );

    useEffect(() => {
        if (isLoggedIn) {
            let total = 0;
            notifications.map((_notification) => {
                total += 1;
                return total;
            });
            setTotalNotifications(total);
        }
    }, [notifications, isLoggedIn]);

    const setUpNotificationListener = useCallback(() => {
        let unsub = () => {
            return;
        };
        if (firebase.auth.currentUser) {
            unsub = firebase.db
                .collection('organization-volunteer-notification')
                .where('organizationId', '==', userData?.organization_id || '')
                .where(
                    'volunteers',
                    'array-contains',
                    firebase.auth.currentUser.uid,
                )
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const broadCastDetails =
                            change.doc.data() as BroadCastType;
                        const participantIndex =
                            broadCastDetails.volunteers.indexOf(
                                firebase.auth.currentUser?.uid as string,
                            );

                        if (participantIndex >= 0 && change.type === 'added') {
                            // Check if the notification is new
                            const timestamp = broadCastDetails.timestamp;
                            if (!timestamp) {
                                broadCastDetails.timestamp =
                                    firebase.getCurrentTimestamp();
                                firebase.updateBroadCastNotificationTimestamp(
                                    broadCastDetails,
                                    change.doc.id,
                                );
                                // Display the notification to the user
                                // You can use the Notification API to display the notification
                                enqueueSnackbar(
                                    'You have a new notification from your organization',
                                    {
                                        variant: 'info',
                                        autoHideDuration: 3000,
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right',
                                        },
                                    },
                                );
                            }

                            setNotifications((prevNotifications) => {
                                let temp = [...prevNotifications];
                                temp.push({
                                    ...broadCastDetails,
                                    id: change.doc.id,
                                } as BroadCastType);
                                return temp;
                            });
                        }
                        if (
                            participantIndex >= 0 &&
                            change.type === 'modified'
                        ) {
                        }
                        if (change.type === 'removed') {
                            // removing
                        }
                    });
                });
        }
        return unsub;
    }, [enqueueSnackbar, userData]);

    useEffect(() => {
        let unsub: any = () => {
            return;
        };
        if (isLoggedIn && userData) {
            unsub = setUpNotificationListener();
        }

        return unsub;
    }, [isLoggedIn, userData, setUpNotificationListener]);

    const showNotification = (message: string, volunteerIds: string[]) => {
        // Code to send notification to Firebase
        //sendNotification(message, volunteerIds);
    };

    //TODO: Might need it future
    // const sendNotification = async (
    //     message: string,
    //     volunteerIds: string[],
    // ) => {
    //     const { messaging } = firebase;
    //     try {
    //         await Notification.requestPermission();
    //         const token = await messaging.getToken();
    //         axios
    //             .post(
    //                 'https://fcm.googleapis.com/fcm/send',
    //                 {
    //                     to: token,
    //                     notification: {
    //                         title: 'Broadcast Notification',
    //                         body: message,
    //                         click_action: 'www.google.com',
    //                         volunteerIds: volunteerIds,
    //                     },
    //                 },
    //                 {
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         Authorization: `key=${process.env.REACT_APP_FCM_SERVER_KEY}`,
    //                     },
    //                 },
    //             )
    //             .then((res) => {
    //                 enqueueSnackbar(
    //                     'Message successfully delivered to related Volunteers',
    //                     {
    //                         variant: 'info',
    //                         autoHideDuration: 3000,
    //                     },
    //                 );
    //             })
    //             .catch((error) => {
    //                 enqueueSnackbar(
    //                     'There was something wrong while delivering notification',
    //                     {
    //                         variant: 'error',
    //                         autoHideDuration: 3000,
    //                     },
    //                 );
    //                 console.error(error);
    //             });
    //     } catch (error) {
    //         enqueueSnackbar(
    //             'There was something wrong while delivering notification',
    //             {
    //                 variant: 'error',
    //                 autoHideDuration: 3000,
    //             },
    //         );
    //         console.error(error);
    //     }
    // };
    const contextValue: NotificationContextType = {
        showNotification,
        notifications,
        totalNotifications,
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
