import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { CommonDataContext } from 'contexts/CommonDataContext';
import {
    Box,
    Button,
    Tooltip,
    Card,
    CardContent,
    Typography,
    Link,
    Avatar,
} from '@material-ui/core';
import { ApprovalType, TeamMember } from 'types';
import './ProjectCard.scss';
import { UserContext } from 'contexts/UserContext';
import { getProjectRequestStatusLabel, getRequestStatus } from 'helpers/utils';

interface ProjectCardProps {
    heading: string;
    subHeading: string;
    groupName: string;
    startTime: any;
    endTime: any;
    duration: number;
    volunteerLocation: number;
    teamHeadline?: string;
    team: Array<TeamMember>;
    isSuggestion: boolean;
    projectId: string;
    handleJoinProject?: (projectId: string, organizationId: string) => void;
    organizationId?: string;
    requestStatus?: ApprovalType[];
}

const useStyles = makeStyles({
    root: {
        // maxWidth: "45rem",
        width: '100%',
        marginBottom: 10,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    team: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    joinBtn: {
        width: '50%',
    },
});

const ProjectCard: FC<ProjectCardProps> = (props: ProjectCardProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [timingString, setTimingString] = useState('');
    const { volunteerLocations, volunteerTimings } =
        useContext(CommonDataContext);
    const { userData } = useContext(UserContext);

    const {
        isSuggestion,
        volunteerLocation,
        duration,
        startTime,
        endTime,
        heading,
        groupName,
        teamHeadline,
        subHeading,
        team,
        handleJoinProject,
        projectId,
        organizationId,
        requestStatus,
    } = props;

    const location = useMemo(() => {
        if (volunteerLocations) {
            for (let i = 0; i < volunteerLocations.length; i++) {
                if (volunteerLocations[i].id === volunteerLocation) {
                    return volunteerLocations[i].name;
                }
            }
            return '';
        } else {
            return '';
        }
    }, [volunteerLocations, volunteerLocation]);

    const totalDuration = useMemo(() => {
        if (volunteerTimings) {
            for (let i = 0; i < volunteerTimings.length; i++) {
                if (volunteerTimings[i].id === duration) {
                    return volunteerTimings[i].name;
                }
            }
            return '';
        } else {
            return '';
        }
    }, [volunteerTimings, duration]);

    useEffect(() => {
        if (startTime && startTime) {
            setTimingString(
                dayjs(startTime).format('MM/DD/YYYY') +
                    ' - ' +
                    dayjs(endTime).format('MM/DD/YYYY'),
            );
        }
    }, [startTime, endTime]);

    const handleClick = (projectId: string, organizationId: string) => {
        handleJoinProject && handleJoinProject(projectId, organizationId);
    };

    const requestStatusLabel = getProjectRequestStatusLabel(
        userData?.requestStatus ?? [],
        userData?.id!,
        organizationId!,
    );

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent className="pc_content">
                <div className="pc_section">
                    <Typography variant="h5" component="div">
                        <Link
                            component="button"
                            variant="h6"
                            onClick={() => {
                                history.push(`${projectId}/project-details`);
                            }}
                        >
                            <Box fontWeight={700}>{heading}</Box>
                        </Link>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h1"
                        className={classes.pos}
                        color="textSecondary"
                    >
                        {groupName}
                    </Typography>
                    {isSuggestion ? (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.joinBtn}
                            disabled={
                                !!(
                                    requestStatusLabel ===
                                        'Congrats. You are part of the team' ||
                                    requestStatusLabel === 'Request Pending'
                                )
                            }
                            onClick={() => {
                                const status = getRequestStatus(
                                    requestStatus ?? [],
                                    userData?.id!,
                                    organizationId!,
                                );
                                //TODO: Need to ask Stephanie what happens if the organization rejects the volunteer
                                // request, can he apply again
                                //status !== 'Rejected' &&
                                status !== 'Approved' &&
                                    handleClick(projectId, organizationId!);
                            }}
                        >
                            {/* {teamHeadline} */}
                            {requestStatusLabel}
                        </Button>
                    ) : (
                        // projectId !==
                        //     requestStatus?.find(
                        //         (req) => req.projectId === projectId,
                        //     )?.projectId ||
                        // requestStatus?.find(
                        //     (req) => req.projectId === projectId,
                        // )?.status !== 'Pending' ? (
                        //     <Button
                        //         variant="contained"
                        //         color="primary"
                        //         className={classes.joinBtn}
                        //         onClick={() =>
                        //             handleClick(projectId, organizationId!)
                        //         }
                        //     >
                        //         {teamHeadline}
                        //     </Button>
                        // ) : (
                        //     <Button
                        //         variant="contained"
                        //         color="secondary"
                        //         className={classes.joinBtn}
                        //     >
                        //         Request pending
                        //     </Button>
                        // )
                        <> {teamHeadline && teamHeadline}</>
                    )}
                    <div className={classes.team}>
                        {team.map(({ name, avatar }) => (
                            <Tooltip title={name} key={name}>
                                <Avatar alt={name} src={avatar} />
                            </Tooltip>
                        ))}
                    </div>
                </div>
                <div className="pc_section pc_spaceTop">
                    <Typography variant="h6" component="div">
                        <Box fontWeight={600} mb={2}>
                            {subHeading}
                        </Box>
                    </Typography>
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {timingString}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {totalDuration}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {location}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default ProjectCard;
