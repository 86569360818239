import React from 'react';
import { Button, Typography } from '@material-ui/core';
import './OurTeam.scss';

const OurTeam: React.FC = () => {
    return (
        <div className="about_root">
            <div className="about_volunteer_types">
                <div className="about_volunteer_types_sec">
                    {/* <ImportantDevicesIcon style={{ fontSize: 60 }}/> */}

                    <div className="g_mt2">
                        <Typography variant="h5" align="center" gutterBottom>
                            Meet the Team
                        </Typography>
                    </div>
                    <div className="about_volunteer_types">
                        <div className="about_volunteer_types_sec">
                            {/* <ImportantDevicesIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                    Tiffany Odoh
                                </Typography>
                            </div>
                            <Typography>
                                Lead Developer/ Back End Technology Architect
                            </Typography>
                        </div>
                        <div className="about_volunteer_types_sec">
                            {/* <LocationOnIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                    Odunayo Obajuluwa
                                </Typography>
                            </div>
                            <Typography>
                                Lead Developer: Front End Design and Developer
                            </Typography>
                        </div>
                        <div className="about_volunteer_types_sec">
                            {/* <LocationOnIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                Itohan Osaigbovo
                                </Typography>
                            </div>
                            <Typography>
                            Director of Narrative Strategy
                            </Typography>
                        </div>
                        <div className="about_volunteer_types_sec">
                            {/* <LocationOnIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                Michara Lane
                                </Typography>
                            </div>
                            <Typography>
                            Director of Digital Marketing Strategy
                            </Typography>
                        </div>
                    </div>
                    <div className="about_vision g_mt2">
                        <Typography>
                            We are growing our team! If you are interested in
                            making a longer term, strategic impact, please reach
                            out to us as <a href="mailto:info@nvestpartners.org">info@nvestpartners.org.</a> In the weeks
                            and months to come, we will be growing our
                            operations team and need donations, sponsorships,
                            and capital investment to allow us to scale our
                            operations.
                        </Typography>
                    </div>
                    <div className="about_our_team_part about_vision g_mt2">
                        <div className="about_content">
                            <Typography variant="h5">Learn More</Typography>
                            <Typography variant="body2">
                                There is more to our story. To find frequently
                                asked questions or comments look through our FAQ
                                page, or contact us for more details.
                            </Typography>
                            <div className="g_row">
                                <div className="g_mr1">
                                    <Button
                                        className="g_primary_btn"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        View Slideshow
                                    </Button>
                                </div>
                                <div className="g_mr1">
                                    <Button variant="outlined" color="default">
                                        Contact Us
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurTeam;
