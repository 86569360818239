import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { SignupContext } from '../../contexts/SignupContext';
import { TextField } from '@material-ui/core';
//import "./Statement.scss";

const Statement: React.FC = () => {
    const { step3DataReferences, setStep3DataReferences } =
        useContext(SignupContext);

    const addReference = () => {
        setStep3DataReferences((pre: any) => [
            ...pre,
            {
                full_name: '',
                relationship: '',
                email: '',
                phone_number: '',
                password: '',
            },
        ]);
    };

    const removeReference = () => {
        setStep3DataReferences((pre: any) => {
            if (pre.length > 1) {
                let temp = [...pre];
                temp.pop();
                return temp;
            } else {
                return pre;
            }
        });
    };

    // useEffect(() => {
    //   console.log(step3Data);

    // }, [step3Data])

    const referencesValueChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
    ) => {
        event.persist();
        setStep3DataReferences((pre: any) => {
            let temp = [...pre];
            temp[index] = {
                ...temp[index],
                [event.target.name]: event.target.value,
            };
            return temp;
        });
    };

    // const valueChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     event.persist();
    //     setStep3Data(event.target.value);
    // };

    return (
        <Container className="root">
            <div>
                {/* <h2 className="has-text-centered is-size-2 mb-3">Create an Account</h2>
        <hr />
        <h3>Step 3: Statement of Intent</h3> */}
                {/* <h3>
                    Please provide a professional reference below <br /> who can
                    provide a recommendation and can confirm your qualifications
                </h3> */}

                <form noValidate autoComplete="off">
                    {/* <div className="row">
                        <TextareaAutosize
                            rowsMax={15}
                            className="statement"
                            aria-label="maximum height"
                            placeholder="Maximum 15 minRows"
                            name="statement"
                            value={step3Data}
                            onChange={valueChanged}
                        />

                    </div> */}

                    <div className="row_centered">
                        <div className="row row_centered">
                            <h3>References</h3>
                        </div>
                    </div>

                    {step3DataReferences &&
                        step3DataReferences.map(
                            (rowData: any, index: number) => {
                                return (
                                    <div key={index} className="ref-cont">
                                        <div className="row">
                                            <TextField
                                                id="fullName"
                                                name={'full_name'}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    referencesValueChanged(
                                                        e,
                                                        index,
                                                    )
                                                }
                                                value={
                                                    step3DataReferences[index]
                                                        .full_name
                                                }
                                                type="text"
                                                label="Full Name*"
                                                variant="outlined"
                                                className="form-field"
                                            />
                                            <TextField
                                                required
                                                id="relationship"
                                                label="Relationship to Applicant"
                                                variant="outlined"
                                                className="form-field"
                                                type="text"
                                                name={'relationship'}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    referencesValueChanged(
                                                        e,
                                                        index,
                                                    )
                                                }
                                                value={
                                                    step3DataReferences[index]
                                                        .relationship
                                                }
                                            />
                                        </div>
                                        <div className="row">
                                            <TextField
                                                required
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                type="text"
                                                autoComplete="email"
                                                className="form-field"
                                                name={'email'}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    referencesValueChanged(
                                                        e,
                                                        index,
                                                    )
                                                }
                                                value={
                                                    step3DataReferences[index]
                                                        .email
                                                }
                                            />
                                            <TextField
                                                required
                                                id="phoneNumber"
                                                label="Phone Number"
                                                variant="outlined"
                                                className="form-field"
                                                name={'phone_number'}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    referencesValueChanged(
                                                        e,
                                                        index,
                                                    )
                                                }
                                                value={
                                                    step3DataReferences[index]
                                                        .phone_number
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    <div className="ref-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={removeReference}
                        >
                            - Remove Reference
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addReference}
                        >
                            + Add Reference
                        </Button>
                    </div>
                    {/* <div className="cta-container">
            <Button variant="contained" color="secondary">
              Save & Continue
            </Button>
          </div> */}
                </form>
            </div>
        </Container>
    );
};
export default Statement;
