import React, { useContext, useState } from 'react';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import heroImg from '../../images/hero-bg-new-sm.png';
import {
    Box,
    Button,
    Container,
    Snackbar,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import SpeedIcon from '@material-ui/icons/Speed';
import GroupIcon from '@material-ui/icons/Group';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'App';
import Search from 'components/Search/Search';
import { UserContext } from 'contexts/UserContext';
import FeaturedProjects from 'components/FeaturedList/FeaturedProjects';
import FeaturedOrganizations from '../../components/FeaturedList/FeaturedOrganizations';
import './HomePage.scss';

export const HomePage = () => {
    const history = useHistory();
    const { isLoggedIn, isChecking } = useContext(UserContext);
    const [showSnackbar, setShowSnackbar] = useState(false);

    // const getCities = (search: string) => {
    //   organizationsIndex.searchForFacetValues("city", search)
    //   .then(result => {
    //     setCityOptions(result.facetHits);
    //   })
    // }

    const handleSeeAllOrganizations = () => {
        if (isLoggedIn) {
            history.push(AppRoutes.organizationOpportunities);
        } else {
            setShowSnackbar(true);
        }
    };

    const handleSeeAllProjects = () => {
        if (isLoggedIn) {
            history.push(AppRoutes.volunteerOpportunities);
        } else {
            setShowSnackbar(true);
        }
    };

    return (
        <div className="hwv_root">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see full list.
                </Alert>
            </Snackbar>
            <Container maxWidth="lg">
                <Grid container className="heroContainer" alignItems="center">
                    <Grid
                        xs={12}
                        sm={6}
                        alignItems="center"
                        className="heroLeft"
                    >
                        <div className="heroTextWrap">
                            <p className="heroText">
                                Welcome to <br />
                                N-Vest Partners
                                <sup>
                                    <span className="trademark">&trade;</span>
                                </sup>
                            </p>
                            <p className="subtitle">
                                We connect Passions, Projects, and People!
                            </p>
                        </div>
                        <div className="input-container search-container">
                            <Search />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="heroRight">
                        <div>
                            <img
                                src={heroImg}
                                alt="hero img"
                                className="heroImg"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            {/* <div className="home_profile_banner">
                <div className="container image_container">
                    <div className="searchTag">
                        <Typography variant="h5" align="center" gutterBottom>
                            Welcome to N-Vest Partners
                        </Typography>
                        <Typography align="center" gutterBottom>
                            We connect Passions, Projects, and People!
                        </Typography>
                    </div>
                    <div className="input-container search-container">
                        <Search />
                    </div>
                </div>
            </div> */}
            <div className="aboutNvest g_pt2 g_pb2 g_mb2">
                <div className="g_mt2">
                    <Typography variant="h5" align="center" gutterBottom>
                        About N-Vest Partners
                    </Typography>
                </div>
                <Container maxWidth="md">
                    <Typography
                        variant="body1"
                        align="center"
                        className="ourvision"
                        gutterBottom
                    >
                        N-Vest Partners is an organization and global networking platform
                        dedicated to connecting highly skilled philanthropic 
                        partners (people, corporations, and academic institutions) 
                        with great organizations, driving greater impact and supporting
                        local development. We take pride in connecting our partners with 
                        the needs and entities across Africa and within the broader Black 
                        and African Diaspora.
                    </Typography>
                </Container>
            </div>
            <div>
                <Typography variant="h5" align="center" gutterBottom>
                    Why N-Vest Partners?
                </Typography>
            </div>
            <div className="home_numbers">
                <div
                    className="home_numbers_sec"
                    style={{ maxWidth: '7rem', textAlign: 'center' }}
                >
                    <TouchAppIcon style={{ fontSize: '5rem' }} />
                    <Typography variant="h5">Simple Application</Typography>
                </div>
                <div
                    className="home_numbers_sec"
                    style={{ maxWidth: '7rem', textAlign: 'center' }}
                >
                    <SpeedIcon style={{ fontSize: '5rem' }} />
                    <Typography variant="h5">
                        High-Impact Organizations
                    </Typography>
                </div>
                <div
                    className="home_numbers_sec"
                    style={{ maxWidth: '7rem', textAlign: 'center' }}
                >
                    <GroupIcon style={{ fontSize: '5rem' }} />
                    <Typography variant="h5">Skillset Match</Typography>
                </div>
            </div>
            <div className="hwv_body g_wfull g_whitebg">
                <Container maxWidth="md">
                    <div className="g_row_center g_mt2 g_mb1">
                        {!isChecking && !isLoggedIn && (
                            <Button
                                onClick={() => history.push(AppRoutes.Signup)}
                                className="g_primary_btn"
                                variant="contained"
                                color="secondary"
                            >
                                Get Started
                            </Button>
                        )}
                    </div>
                    <div className="g_row_center g_mb2">
                        <Typography variant="h5">Featured Projects</Typography>
                    </div>
                    <div className="hw_organization_list_home_page">
                        <FeaturedProjects />
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Button
                                disabled={isChecking}
                                onClick={handleSeeAllProjects}
                                className="g_primary_btn"
                                variant="contained"
                                color="secondary"
                            >
                                See Full List &gt;&gt;
                            </Button>
                        </Box>
                    </div>

                    <div className="g_row_center g_mb2 g_mt4">
                        <Typography variant="h5">
                            Featured Organizations
                        </Typography>
                    </div>
                    <div className="hw_organization_list_home_page">
                        <FeaturedOrganizations />
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Button
                                disabled={isChecking}
                                onClick={handleSeeAllOrganizations}
                                className="g_primary_btn"
                                variant="contained"
                                color="secondary"
                            >
                                See Full List &gt;&gt;
                            </Button>
                        </Box>
                    </div>
                    {/* <div className="home_numbers">
                        <div className="home_numbers_sec">
                            <Typography variant="h3">2023</Typography>
                            <Typography>Established</Typography>
                        </div>
                        <div className="home_numbers_sec">
                            <Typography variant="h3">10</Typography>
                            <Typography>Organizations</Typography>
                        </div>
                        <div className="home_numbers_sec">
                            <Typography variant="h3">14.8k</Typography>
                            <Typography>Projects Launched</Typography>
                        </div>
                        <div className="home_numbers_sec">
                            <Typography variant="h3">9.6k</Typography>
                            <Typography>Volunteer</Typography>
                        </div>
                    </div> */}
                    <div className="about_our_team_sec">
                        <div className="about_our_team_part">
                            <div
                                className="about_content"
                                style={{
                                    backgroundColor: '#F3EEE2',
                                    border: 'none',
                                }}
                            >
                                <Typography
                                    style={{ color: 'black' }}
                                    variant="h5"
                                >
                                    Our Founder's Story
                                </Typography>
                                <Typography
                                    style={{ color: 'black' }}
                                    variant="body2"
                                >
                                    “N-Vest Partners is inspired by my love 
                                    for Black and African communities and desire 
                                    to invest in sustainable ways.
                                    <br />
                                    I continue to be inspired by the impact local
                                    organizations have on their communities. I 
                                    have built a new platform where our work is committed to N-Vesting in the important work that is already taking place, rather than fighting for resources for new initiatives: allowing for local organizations to have stronger, larger, and long-lasting Impact.”{' '}
                                    <br /> - Emiola Taiwo
                                </Typography>
                                <div>
                                    <Link
                                        className=""
                                        to={AppRoutes.AboutNvest}
                                    >
                                        <Button
                                            className="g_primary_btn"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Read Full Story
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="about_our_team_part">
                            <div className="founders_story_img">
                                {/* <img src={logo} alt="logo" /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="g_mt4">
                        <Typography variant="h5" align="center" gutterBottom>
                        Connect Your Skills to Those Who Need it the Most
                        </Typography>
                    </div> */}
                    {/* <div className="g_row_center g_mt2 g_mb1">
                        {!firebase.isAuthenticated() && (
                            <div className="hw_selected_button">
                                <Button
                                    className="g_primary_btn"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        history.push('/signup');
                                    }}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        )}
                        <div className="hw_selected_button">
                            <Button
                                fullWidth
                                color="default"
                                variant="outlined"
                            >
                                Contact Us
                            </Button>
                        </div>
                    </div> */}
                    {/* <div className="g_mt4">
            <Typography variant="h5" align="center" gutterBottom>
              Signup for our newsletter
            </Typography>
          </div> */}
                    {/* <div className="g_row_center g_mt2 g_mb1">
            <div className="hw_email_input">
              <TextField
                value={email}
                variant="outlined"
                size="small"
                placeholder="Email..."
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="hw_selected_button">
              <Button
                fullWidth
                color="secondary"
                variant="contained"
              >
                <Box color="white">
                  Signup
                </Box>
              </Button>
            </div>
          </div> */}
                </Container>
            </div>
        </div>
    );
};
