import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Forgot from './Pages/Forgot/Forgot';
import About from './Pages/About/About';
import Profile from './Pages/Profile/Profile';
import ProfilePreview from './Pages/Profile/Profile';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import SignupContextProvider from './contexts/SignupContext';
import CommonDataContextProvider from './contexts/CommonDataContext';
import './App.scss';
import { HowWorksVolunteer } from './Pages/HowItWorks/HowWorksVolunteer';
import { HowWorksOrganization } from './Pages/HowItWorks/HowWorksOrganization';
import VolunteerOpportunities from './Pages/HowItWorks/VolunteerOpportunities';
import OrganizationOpportunities from './Pages/HowItWorks/OrganizationOpportunities';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import OrganizationPublicPage from './Pages/OrganizationPublicPage/OrganizationPublicPage';
import Donation from './Pages/Donation/Donation';
import MessageContextProvider from './contexts/MessageContext';
import { HomePage } from './Pages/HowItWorks/HomePage';
import ScrollToTop from 'components/ScrollToTop';
import Newsletter from 'Pages/Newsletter';
import ProjectDetail from 'components/ProjectDetail/ProjectDetail';
import VolunteerProfilePage from './Pages/VolunteerProfilePage/VolunteerProfilePage';
import ChatCard from 'components/ChatCard/ChatCard';
import ContactUs from 'components/Contact';
import AboutNvest from './Pages/AboutNvest/AboutNvest';
import FounderStory from './Pages/FounderStory/FounderStory';
import OurTeam from './Pages/OurTeam/OurTeam';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';

export enum AppRoutes {
    Landing = '/',
    Login = '/login',
    Signup = '/signup',
    About = '/about',
    Forgot = '/forgot',
    Profile = '/profile',
    ProfilePreview = '/profile/:id',
    HowWorksVolunteer = '/how-it-works-volunteer',
    HowWorksOrganization = '/how-it-works-organization',
    opportunities = '/opportunities',
    volunteerOpportunities = '/opportunities/volunteer',
    organizationOpportunities = '/opportunities/organization',
    organizationHome = '/organization/:id',
    donation = '/donation',
    careers = '/careers',
    blog = '/blog',
    successStories = '/success-stories',
    contactUs = '/contact-us',
    faq = '/faq',
    newsletter = '/newsletter',
    projectDetails = '/:id/project-details',
    chat = '/chat',
    VolunteerProfilePage = '/volunteer-profile-page/:id',
    AboutNvest = '/aboutnvest',
    FounderStory = '/founderstory',
    OurTeam = '/ourteam',
    PrivacyPolicy = '/privacy-policy',
    TermsAndCondition = '/terms-and-condition',
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#CB5F25',
        },
        secondary: {
            main: '#CB5F25',
        },
    },
});

const App: FC = () => {
    return (
        <>
            <ScrollToTop />
            <MuiThemeProvider theme={theme}>
                <CommonDataContextProvider>
                    <SignupContextProvider>
                        <MessageContextProvider>
                            <div className="app-wa app-wa-wrapper">
                                <div className="header_container">
                                    <Header showAvatar={true}></Header>
                                </div>
                                <div className="app-wa__main">
                                    <Switch>
                                        <Route
                                            exact
                                            path="/login"
                                            component={Login}
                                        />
                                        <Route
                                            exact
                                            path="/"
                                            component={HomePage}
                                        />
                                        <Route
                                            path={AppRoutes.Signup}
                                            component={Signup}
                                        />
                                        <Route
                                            path={AppRoutes.About}
                                            component={About}
                                        />
                                        <Route
                                            path={AppRoutes.Forgot}
                                            component={Forgot}
                                        />
                                        <Route
                                            path={AppRoutes.Profile}
                                            component={Profile}
                                        />
                                        <Route
                                            exact
                                            path={AppRoutes.ProfilePreview}
                                            component={ProfilePreview}
                                        />
                                        <Route
                                            path={AppRoutes.HowWorksVolunteer}
                                            component={HowWorksVolunteer}
                                        />
                                        <Route
                                            path={
                                                AppRoutes.VolunteerProfilePage
                                            }
                                            component={VolunteerProfilePage}
                                        />
                                        <Route
                                            path={
                                                AppRoutes.HowWorksOrganization
                                            }
                                            component={HowWorksOrganization}
                                        />
                                        <Route
                                            path={
                                                AppRoutes.volunteerOpportunities
                                            }
                                            component={VolunteerOpportunities}
                                        />
                                        <Route
                                            path={
                                                AppRoutes.organizationOpportunities
                                            }
                                            component={
                                                OrganizationOpportunities
                                            }
                                        />
                                        <Route
                                            path={AppRoutes.donation}
                                            component={Donation}
                                        />
                                        <Route
                                            path={AppRoutes.newsletter}
                                            component={Newsletter}
                                        />
                                        <Route
                                            path={AppRoutes.projectDetails}
                                            component={ProjectDetail}
                                        />
                                        <Route
                                            path={AppRoutes.organizationHome}
                                            render={(props) => (
                                                <OrganizationPublicPage
                                                    {...props}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={AppRoutes.careers}
                                            component={() => null}
                                        />
                                        <Route
                                            path={AppRoutes.blog}
                                            component={() => null}
                                        />
                                        <Route
                                            path={AppRoutes.successStories}
                                            component={() => null}
                                        />
                                        <Route
                                            path={AppRoutes.chat}
                                            component={(props: any) => (
                                                <ChatCard {...props} />
                                            )}
                                        />
                                        <Route
                                            path={AppRoutes.contactUs}
                                            component={ContactUs}
                                        />
                                        <Route
                                            path={AppRoutes.AboutNvest}
                                            component={AboutNvest}
                                        />
                                        <Route
                                            path={AppRoutes.FounderStory}
                                            component={FounderStory}
                                        />
                                        <Route
                                            path={AppRoutes.OurTeam}
                                            component={OurTeam}
                                        />
                                        <Route
                                            path={AppRoutes.PrivacyPolicy}
                                            component={PrivacyPolicy}
                                        />
                                        <Route
                                            path={AppRoutes.TermsAndCondition}
                                            component={TermsAndConditions}
                                        />

                                        <Redirect to="/login" />
                                    </Switch>
                                </div>
                                <div className="app-wa__footer">
                                    <Footer></Footer>
                                </div>
                            </div>
                        </MessageContextProvider>
                    </SignupContextProvider>
                </CommonDataContextProvider>
            </MuiThemeProvider>
        </>
    );
};

export default App;
