import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { NewsContext } from '../../contexts/NewsContext';
import { NewsType } from '../../types';
import TimeAgo from 'react-timeago';

export const NewsFeed = () => {
    const { news } = useContext(NewsContext);

    return (
        <div className="nf_container g_wfull">
            <div className="g_row g_tspacer3 g_bspacer2">
                <Typography variant="h4">
                    {news?.length === 0
                        ? 'Your newsfeed is empty'
                        : 'My Newsfeed'}
                </Typography>
            </div>
            <div className="nf_array">
                {!news ? (
                    <CircularProgress />
                ) : (
                    <>
                        {news.map((item: NewsType, index: number) => (
                            <Card
                                className="nf_card g_mb1"
                                key={item.poster_id}
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="recipe"
                                            src={item.poster_icon}
                                        />
                                    }
                                    title={item.poster_name}
                                    subheader={
                                        //TODO: Need to take a look at it later
                                        // item?.timestamp?.toDate()||
                                        <TimeAgo
                                            date={new Date().toLocaleString()}
                                        />
                                    }
                                />
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {item.news}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button className="nf_card_actionbutton">
                                        Like
                                    </Button>
                                    <Button className="nf_card_actionbutton">
                                        Comment
                                    </Button>
                                </CardActions>
                            </Card>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};
