import React, { FunctionComponent } from 'react';
import { Dialog } from '@material-ui/core';

type ModalProps = {
    isOpen: boolean;
    handleClose: Function;
    maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
    fullWidth?: boolean;
    children: React.ReactNode;
};
const ModalPopup: FunctionComponent<ModalProps> = (props) => {
    const {
        isOpen,
        handleClose,
        maxWidth = 'md',
        children,
        fullWidth = false,
    } = props;
    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose()}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            {children}
        </Dialog>
    );
};

export default ModalPopup;
