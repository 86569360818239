import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'utils/auth';
import { ChatType, UserType } from 'types';
import { getProjectActivityType, getWorkDescription } from 'helpers/utils';
import './VolunteerProfilePage.scss';
import ChatCard from 'components/ChatCard/ChatCard';
import { MessageContext } from 'contexts/MessageContext';

interface ParamTypes {
    id: string;
}

const VolunteerProfilePage = () => {
    const { id: volunteerId } = useParams<ParamTypes>();
    const history = useHistory();
    const { chats } = useContext(MessageContext);
    const [user, setUser] = useState<UserType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showChatPopup, setShowChatPopup] = useState(false);

    const getUserData = useCallback(async (id: string) => {
        setIsLoading(true);
        const user = await firebase.getUserDataById(id);
        setIsLoading(false);
        setUser(user);
    }, []);

    useEffect(() => {
        if (volunteerId) {
            getUserData(volunteerId);
        }
    }, [volunteerId, getUserData]);

    if (isLoading)
        return (
            <Box
                width="100%"
                height="16rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );

    const handleContactMe = async () => {
        let userChatData = chats.find((chat: ChatType) =>
            chat.participants.includes(volunteerId),
        );
        if (!userChatData) {
            await firebase.createReply('', 0, volunteerId);
        }
        setShowChatPopup(true);
    };

    return (
        <div className="page_bg">
            <div className="volunteer_header">
                <h4>
                    {user?.first_name} {user?.last_name}
                </h4>
            </div>
            <div className="profile_pic">
                <img src={user?.avatar || ''} alt="cover" />
            </div>
            <div className="volunteer_page_body">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button
                            size="medium"
                            color={'primary'}
                            variant="contained"
                            onClick={() => history.goBack()}
                        >
                            Go back
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4">About Me</Typography>
                        <Box>
                            <Typography variant="subtitle1">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim volunteerId est
                                laborum.
                            </Typography>
                            <Typography variant="subtitle1"></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Interested In</Typography>
                        <Box>
                            <Typography variant="subtitle1">
                                {`${getWorkDescription(user?.work_type)} work`}
                            </Typography>
                            <Typography variant="subtitle1"></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Volunteer Preferences
                        </Typography>
                        <Box>
                            <Typography variant="subtitle1">
                                {getProjectActivityType(user?.timing)}
                            </Typography>
                            <Typography variant="subtitle1"></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Availability</Typography>
                        <Box>
                            <Typography variant="subtitle1">
                                3/4 hours a week
                            </Typography>
                            <Typography variant="subtitle1"></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Skills & Categories
                        </Typography>
                        <Box>
                            <Typography variant="subtitle1">
                                <ul>
                                    {user?.skills?.map((skill) => (
                                        <li key={skill}>{skill}</li>
                                    ))}
                                </ul>
                            </Typography>
                            <Typography variant="subtitle1"></Typography>
                        </Box>
                    </Grid>
                    <div className="contact_Btn">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleContactMe}
                        >
                            <Box color="white">Contact Me</Box>
                        </Button>
                    </div>
                </Grid>
            </div>

            {showChatPopup && (
                <ChatCard
                    chatData={chats.find((chat: ChatType) =>
                        chat.participants.includes(volunteerId),
                    )}
                    isContact={showChatPopup}
                    closeVolunteerContact={() =>
                        setShowChatPopup((prevState) => !prevState)
                    }
                    showChatCard={false}
                />
            )}
        </div>
    );
};

export default VolunteerProfilePage;
