import React, { useContext, useEffect, useState, useCallback } from 'react';
import { uniqBy } from 'lodash';
import {
    Card,
    Typography,
    Grid,
    Box,
    makeStyles,
    CardContent,
    Button,
} from '@material-ui/core';
import { MessageContext } from '../../contexts/MessageContext';
import './Messages.scss';
import ChatCard from 'components/ChatCard/ChatCard';
import { ChatType } from 'types';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { orderBy } from 'lodash';
import ModalPopup from 'components/Modal/Modal';

type chatUserType = {
    id: string;
    userName: string;
};

const useStyles = makeStyles((theme) => ({
    usersList: {
        height: '400px',
        overflowY: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    messageList: {
        height: 'auto',
        overflowY: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    availableUserToChat: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        cursor: 'pointer',
        backgroundColor: 'whitesmoke !important',
        margin: '10px',
    },
    cardContent: {
        padding: '10px',
        paddingBottom: '10px !important',
        textAlign: 'left',
    },
    selectUser: {
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    heading: {
        color: theme.palette.text.primary,
    },
    selectedUser: {
        backgroundColor: '#135279 !important',
        color: 'white',
    },
    center: {
        justifyContent: 'center',
    },
}));
export const Messages = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { chats } = useContext(MessageContext);
    const [chatUserList, setChatUserList] = useState<
        { id: string; userName: string }[]
    >([]);
    const [selectedChatUser, setSelectedChatUser] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        firebase.db
            .collection('users')
            .orderBy('email', 'desc')
            .onSnapshot((snapshot: any) => {
                setChatUserList(
                    snapshot.docs.map((doc: any) => {
                        return {
                            id: doc.id,
                            userName: doc.data().first_name,
                        };
                    }),
                );
            });
    }, []);

    const handleSelectedUserToChat = useCallback(
        async (id: string) => {
            setSelectedChatUser(id);
            let returnData = await firebase.createReply('', 0, id);
            if (returnData.status === 'error') {
                enqueueSnackbar('network issue!', {
                    variant: 'error',
                    autoHideDuration: 1000,
                });
            }
        },
        [enqueueSnackbar],
    );

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedChatUser('');
    };

    return (
        <div className="msg_container g_wfull">
            <Grid container>
                <Grid item xs={12} md={12}>
                    {chats.length === 0 ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <div className="g_row_center g_tspacer2 g_bspacer2">
                                <Typography variant="h4">
                                    Your inbox is empty.
                                </Typography>

                                <Button
                                    className="pl_createBtn"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                >
                                    Start Chat
                                </Button>
                            </div>
                        </Box>
                    ) : (
                        <>
                            <div className="g_row_sb g_bspacer2">
                                <Typography variant="h4">
                                    My Messages
                                </Typography>
                                <Button
                                    className="pl_createBtn"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                >
                                    Start Chat
                                </Button>
                            </div>

                            <div className={classes.messageList}>
                                {orderBy(
                                    chats,
                                    ['updated_at', 'created_at'],
                                    ['asc'],
                                ).map((chat: ChatType) => {
                                    return (
                                        <ChatCard
                                            chatData={chat}
                                            key={chat.id}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>

            <ModalPopup
                fullWidth={true}
                isOpen={isModalOpen}
                handleClose={handleClose}
            >
                <div>
                    <div className={classes.center + 'g_row g_bspacer2'}>
                        <Typography className={classes.selectUser} variant="h4">
                            Select User
                        </Typography>
                    </div>
                    <Box className={classes.usersList}>
                        {uniqBy(chatUserList, 'userName')
                            .filter((user: chatUserType) => !!user.userName)
                            .filter(
                                (user: chatUserType) =>
                                    user.id !==
                                    firebase?.auth?.currentUser?.uid,
                            )
                            .map((user: chatUserType) => {
                                return (
                                    <Card
                                        key={user.id}
                                        className={`${
                                            classes.availableUserToChat
                                        } ${
                                            selectedChatUser === user.id
                                                ? classes.selectedUser
                                                : ''
                                        }`}
                                        onClick={() => {
                                            handleSelectedUserToChat(user.id);
                                            setIsModalOpen(false);
                                        }}
                                    >
                                        <CardContent
                                            className={classes.cardContent}
                                        >
                                            <Typography
                                                className={classes.heading}
                                                color="primary"
                                                variant="h5"
                                                component="h2"
                                            >
                                                {user.userName}
                                            </Typography>
                                        </CardContent>
                                        <div />
                                    </Card>
                                );
                            })}
                    </Box>
                </div>
            </ModalPopup>
        </div>
    );

    // return <Chat />;
};
