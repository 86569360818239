import React, { useEffect, useState } from 'react';
import firebase from 'utils/auth';
import { OrganizationType } from 'types';
import { CircularProgress, Snackbar } from '@material-ui/core';
import FeaturedCard from '../FeaturedCard/FeaturedCard';
import './FeaturedList.scss';
import { Alert } from '@material-ui/lab';

const OrganizationList = () => {
    const [list, setList] = useState<Array<OrganizationType>>([]);
    const [isActive, setIsActive] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getFeaturedOrganizations = async () => {
            let { status, payload } = await firebase.getFeaturedOrganizations();
            setIsLoading(false);
            if (status === 'ok') {
                if (isActive) {
                    setList(payload as []);
                }
            } else {
                // react to error
            }
        };
        getFeaturedOrganizations();

        return () => {
            setIsActive(false);
        };
    }, [isActive]);

    // const handleSeeFullList = () => {
    //     if (isLoggedIn) {
    //         history.push(AppRoutes.organizationOpportunities);
    //     } else {
    //         setShowSnackbar(true);
    //     }
    // };

    return (
        <div className="hw_organization_list">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see full list of organizations
                </Alert>
            </Snackbar>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    {list.map((item: OrganizationType, index: number) => {
                        return (
                            <FeaturedCard
                                key={index}
                                id={item.id}
                                name={item.company}
                                description={item.overview}
                                location={`${item.city}, ${item.country}`}
                                image_url={
                                    item.cover_picture_url ??
                                    'https://firebasestorage.googleapis.com/v0/b/nvestbackup-dev.appspot.com/o/companies%2F4796631635_cffa822984_b.jpg?alt=medi&token=6534c08b-1a4d-4e95-b34b-c590946941d6'
                                }
                                variant="organization"
                            />
                        );
                    })}
                    {/* <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width={'100%'}
                        padding="10px"
                    >
                        <Button
                            disabled={isChecking}
                            onClick={handleSeeFullList}
                            className="g_primary_btn"
                            color="primary"
                            variant="contained"
                        >
                            See Full List
                        </Button>
                    </Box> */}
                </>
            )}
        </div>
    );
};

export default OrganizationList;
