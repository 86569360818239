// useJoinProject.ts
import { useCallback } from 'react';
import firebase from '../utils/auth';

interface UserData {
    id: string;
    first_name?: string;
    last_name?: string;
}

interface SnackbarOptions {
    variant: 'success' | 'error';
    autoHideDuration: number;
    anchorOrigin: {
        vertical: 'top' | 'bottom';
        horizontal: 'left' | 'center' | 'right';
    };
}

type Response =
    | {
          payload: {
              message: string;
          };
          status: number;
          error?: undefined;
      }
    | {
          status: string;
          error: any;
          payload?: undefined;
      };

const useJoinProject = (
    userData: UserData | null,
    enqueueSnackbar: (message: string, options: SnackbarOptions) => void,
) => {
    const handleJoinProject = useCallback(
        async (projectId: string, organizationId: string) => {
            if (userData?.id) {
                const response: Response =
                    await firebase.createVolunteerRequestToJoinOrg(
                        projectId,
                        organizationId,
                        userData.id,
                        `${userData?.first_name} ${userData?.last_name}`,
                    );
                if (response.status === 200) {
                    enqueueSnackbar(
                        'Request successfully sent to the organization!',
                        {
                            variant: 'success',
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        },
                    );
                } else {
                    enqueueSnackbar(response.error, {
                        variant: 'error',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    });
                }
            }
        },
        [
            userData?.id,
            userData?.first_name,
            userData?.last_name,
            enqueueSnackbar,
        ],
    );

    return { handleJoinProject };
};

export default useJoinProject;
