import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
type MapMarkerProps = {
    lat: number;
    lng: number;
    text: string;
};

const useStyles = makeStyles((theme) => ({
    circle: {
        background: '#d83b01',
        borderRadius: '50%',
        color: '#fff',
        height: '2.5em',
        position: 'relative',
        width: '2.5em',
        border: '1px solid transparent',
    },
    circleText: {
        textAlign: 'center',
        height: '50%',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
    },
}));

const MapMarker: FC<MapMarkerProps> = (props: MapMarkerProps) => {
    const classes = useStyles();
    return (
        <div className={classes.circle}>
            <span className={classes.circleText} title={props.text}>
                {props.text}
            </span>
        </div>
    );
};

export default MapMarker;
