export const getSignupEmailBody = (applicant_name: string) => {
    return `<p>Hello ${applicant_name},</p>

   <p>We have received your application. We are excited that you would like to join our community. At N-Vest Partners, we are committed to leveraging our global network of academic institutions, corporate entities, and individuals to establish relationships that are sustainable and impactful.</p>
 
   <p>We are reviewing your application and will get back to you in 24-48 hours.</p>
 
   <p>Thank you,<br>
     N-Vest Partners</p>`;
};
