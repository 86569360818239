import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    TextField,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

interface IEditDialog {
    type: 'double' | 'simple';
    isPassword: boolean;
    isPassword2?: boolean;
    isOpen: boolean;
    onClose: any;
    onUpdate: any;
    title: string;
    heading: string;
    heading2?: string;
    initialValue: string;
    initialValue2?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1rem',
        boxSizing: 'border-box',
        width: '260px',
        margin: 'auto',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '3rem',
    },
    text: {
        marginBottom: '0',
        fontWeight: 500,
        fontSize: '.9rem',
    },
}));

const EditDialog = (props: IEditDialog) => {
    const classes = useStyles();

    const [text, setText] = useState(props.initialValue);
    const [text2, setText2] = useState(props.initialValue2);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <div className={classes.root}>
                <p className={classes.text}>{props.heading}</p>
                <TextField
                    value={text}
                    fullWidth={true}
                    placeholder={'type here...'}
                    type={props.isPassword ? 'password' : 'text'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setText(e.target.value)
                    }
                />
                {props.type === 'double' && (
                    <>
                        <p className={classes.text}>{props.heading2}</p>
                        <TextField
                            value={text2}
                            fullWidth={true}
                            placeholder={'type here...'}
                            type={props.isPassword2 ? 'password' : 'text'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setText2(e.target.value)
                            }
                        />
                    </>
                )}
                <div className={classes.buttons}>
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            props.isPassword2 && setText2('');
                            props.isPassword && setText('');
                            props.onUpdate(text, text2);
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Update
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditDialog;
