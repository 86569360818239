import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Backdrop,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ProjectsList from 'components/ProjectsList/ProjectsList';
import { OrganizationType, ProjectType, SocialMediaType } from 'types';
import { CommonDataContext } from '../../contexts/CommonDataContext';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import AboutUsSection from 'components/AboutUsSection/AboutUsSection';
import { UserContext } from 'contexts/UserContext';
import OrganizationBanner from 'components/OrganizationBanner/OrganizationBanner';
import VolunteerSuggestion from 'components/VolunteerSuggestion';
import ProfilePictureUpdator from 'components/MyInformation/ProfilePictureUpdator';
import { nanoid } from 'nanoid';
import Communication from 'components/Communication/Communication';
import { Location } from '../../types/index';
import './Profile.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '55rem',
            margin: 'auto',
        },
        topArea: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '3rem',
        },
        bottomArea: {},
        avatar: {
            position: 'relative',
            top: '-2rem',
            left: '3rem',
        },
        tabsContainer: {
            boxShadow: 'none',
            maxWidth: '45rem',
        },
        tabPanel: {
            color: '#000',
        },
        messageTabPanel: {
            color: '#000',
            fontColor: '#000',
            maxWidth: '80rem',
        },
    }),
);

interface OrganizationProfileProps {
    totalUnreadChats: number;
    totalApprovalCount: number;
}

const OrganizationProfile = (props: OrganizationProfileProps) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const [projects, setProjects] = useState<Array<ProjectType> | null>(null);
    const [organizationData, setOrganizationData] =
        useState<OrganizationType | null>(null);

    const { userData } = useContext(UserContext);

    const { mobileViewBig } = useContext(CommonDataContext);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationLocation, setOrganizationLocation] = useState<{
        label: string;
        value: Record<string, unknown>;
    }>({ label: '', value: {} });

    const [isLoading, setIsLoading] = useState(false);

    const updateProjectsData = useCallback(async () => {
        let returnData = await firebase.getOrganizationProjects();
        if (returnData && returnData.status === 'ok') {
            setProjects(returnData?.payload as []);
        } else {
            enqueueSnackbar('Error! Try refreshing the page.', {
                key: 'updateProjectsDataError',
                variant: 'error',
            });
            setProjects([]);
        }
    }, [enqueueSnackbar]);

    const updateOrganizationData = useCallback(async () => {
        let returnData = await firebase.getOrganizationDataById(
            userData?.organization_id ?? '',
        );
        if (returnData) {
            setOrganizationData(returnData);
        } else {
            enqueueSnackbar('Error! Try refreshing the page.', {
                key: 'updateAboutUsDataError',
                variant: 'error',
            });
            setOrganizationData(null);
        }
    }, [enqueueSnackbar, userData]);

    useEffect(() => {
        if (userData?.organization_id) {
            updateProjectsData();
            updateOrganizationData();
        }
    }, [userData, updateProjectsData, updateOrganizationData]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (organizationData && organizationData.location) {
            setOrganizationName(organizationData.company);
            setOrganizationLocation({
                label: `${organizationData.location.address.label}`,
                value: organizationData.location.address.value,
            });
        }
    }, [organizationData]);

    //TODO: Can be improved by merging the functions into one function
    const updateVision = useCallback(
        async (vision: string) => {
            setIsLoading(true);
            await firebase.updateVision(
                vision,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );
    const updateImpact = useCallback(
        async (impact: string) => {
            setIsLoading(true);
            await firebase.updateImpact(
                impact,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateMission = useCallback(
        async (mission: string) => {
            setIsLoading(true);
            await firebase.updateMission(
                mission,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateSuccessStories = useCallback(
        async (successStories: string) => {
            setIsLoading(true);
            await firebase.updateSuccessStories(
                successStories,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateFinancials = useCallback(
        async (financials: string) => {
            setIsLoading(true);
            await firebase.updateFinancials(
                financials,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateOurTeam = useCallback(
        async (ourTeam: string) => {
            setIsLoading(true);
            await firebase.updateOurTeam(
                ourTeam,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateSocials = useCallback(
        async (newSocials: Array<SocialMediaType>) => {
            setIsLoading(true);
            await firebase.updateSocials(
                newSocials,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const updateOverview = useCallback(
        async (newOverview: string) => {
            setIsLoading(true);
            await firebase.updateOverview(
                newOverview,
                userData?.organization_id ?? '',
            );
            await updateOrganizationData();
            setIsLoading(false);
        },
        [userData, updateOrganizationData],
    );

    const handleUpdateAvatar = async (file: File) => {
        setIsLoading(true);
        let error = await firebase.updateAvatar(file);
        setIsLoading(false);
        if (error) {
            enqueueSnackbar('Network Issue!', {
                key: nanoid(16),
                variant: 'error',
            });
        }
    };

    if (!organizationData) {
        return (
            <Backdrop style={{ zIndex: 9999999 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    const handleUpdateOrganizationName = async (orgData: {
        company: string;
        location: Location;
    }) => {
        const response = await firebase.updateOrganization(
            orgData,
            userData?.organization_id!,
        );
        if (response.status === 'ok') {
            setOrganizationName(organizationName);
        }
    };

    const handleOrganizationNameChange = (organizationName: string) => {
        setOrganizationName(organizationName);
    };

    const handleOrganizationLocationChange = (location: {
        label: string;
        value: Record<string, unknown>;
    }) => {
        setOrganizationLocation(location);
    };

    return (
        <div className="g_whitebg">
            <Backdrop style={{ zIndex: 9999999 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <OrganizationBanner
                variant="private"
                companyName={organizationName}
                location={organizationLocation}
                socials={organizationData ? organizationData.socials : []}
                updateSocials={updateSocials}
                mobileViewBig={mobileViewBig}
                coverPicture={organizationData?.cover_picture_url || ''}
                updateOrganization={handleUpdateOrganizationName}
                onChangeOrganizationName={handleOrganizationNameChange}
                onChangeOrganizationLocation={handleOrganizationLocationChange}
            />
            <div className={classes.root}>
                <div className={classes.topArea}>
                    {!mobileViewBig && (
                        <div className={classes.avatar}>
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <div className="mi_avatarContainer">
                                    <ProfilePictureUpdator
                                        img={userData?.avatar || ''}
                                        handleUpdateAvatar={handleUpdateAvatar}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <AppBar
                        className={classes.tabsContainer}
                        color="transparent"
                        position="static"
                    >
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            variant="scrollable"
                        >
                            <Tab label="Our Information" {...a11yProps(1)} />
                            <Tab label="Our Projects" {...a11yProps(0)} />
                            <Tab label="Communication" {...a11yProps(2)} />
                            {/* <Tab label="Communication" {...a11yProps(3)} /> */}
                        </Tabs>
                    </AppBar>
                </div>
                <div className={classes.bottomArea}>
                    <TabPanel
                        className={classes.tabPanel}
                        value={value}
                        index={0}
                    >
                        <AboutUsSection
                            variant={'private'}
                            overview={
                                organizationData
                                    ? organizationData.overview
                                    : ''
                            }
                            vision={
                                organizationData ? organizationData.vision : ''
                            }
                            mission={
                                organizationData ? organizationData.mission : ''
                            }
                            successStories={
                                organizationData
                                    ? organizationData.success_stories
                                    : ''
                            }
                            financials={
                                organizationData
                                    ? organizationData.financials
                                    : ''
                            }
                            impact={
                                organizationData ? organizationData.impact : ''
                            }
                            updateOverview={updateOverview}
                            updateVision={updateVision}
                            updateImpact={updateImpact}
                            updateMission={updateMission}
                            updateSuccessStories={updateSuccessStories}
                            updateFinancials={updateFinancials}
                            updateOurTeam={updateOurTeam}
                            ourMembers={organizationData.ourMembers}
                            organizationId={organizationData.id}
                        />
                    </TabPanel>
                    <TabPanel
                        className={classes.tabPanel}
                        value={value}
                        index={1}
                    >
                        {!projects ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <ProjectsList
                                    titleVariant="organization"
                                    projectsData={projects}
                                    updateProjectsData={updateProjectsData}
                                    setLoading={setIsLoading}
                                />
                                <VolunteerSuggestion projectsData={projects} />
                            </>
                        )}
                    </TabPanel>
                    <TabPanel
                        className={classes.messageTabPanel}
                        value={value}
                        index={2}
                    >
                        <Communication
                            totalUnreadChats={props.totalUnreadChats}
                            totalApprovalCount={props.totalApprovalCount}
                        />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default OrganizationProfile;
