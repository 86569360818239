import React, { createContext, useEffect, useState } from 'react';
import firebase from "../utils/auth";

export const NewsContext = createContext();

const NewsContextProvider = (props) => {

  const [ news, setNews ] = useState(null);

  useEffect(()=>{
    const getList = async () => {
      let list = await firebase.getNews();
      setNews(list);
    };
    getList()
  }, [])

  return (
    <NewsContext.Provider value={{ news }}>
      {props.children}
    </NewsContext.Provider>
  )
}

export default NewsContextProvider;
