import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    iframe: {
        border: 'none',
        background: 'white',
        paddingTop: '4rem',
        paddingBottom: '4rem',
        height: '700px',
        [theme.breakpoints.up('sm')]: {
            height: '600px',
        },
    },
}));

export default function Newsletter() {
    const classes = useStyles();

    return (
        <iframe
            className={classes.iframe}
            title="newsletter"
            src="https://docs.google.com/forms/d/e/1FAIpQLScEAKRzi8GgD7sjPofAKPajwD--Z6z5P7szdg13gakNn1nOTg/viewform"
            width="100%"
        >
            Loading…
        </iframe>
    );
}
