import React, { useContext, useEffect, useState } from 'react';
import {
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { CancelOutlined, Check, Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Inbox from '../../components/Inbox/Inbox';
import VolunteerProjects from 'components/VolunteerProjects';
import ProjectsContextProvider from 'contexts/ProjectsContext';
import { CommonDataContext } from '../../contexts/CommonDataContext';
import GoogleLocation from '../../components/AutocompleteLocation/Location';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { MyInformation } from '../../components/MyInformation/MyInformation';
import firebase from 'utils/auth';
import { UserContext } from 'contexts/UserContext';
import { Location } from '../../types/index';
import './Profile.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            // "& > *": {
            //   margin: theme.spacing(1),
            // },
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '55rem',
            margin: 'auto',
        },
        topArea: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '3rem',
        },
        bottomArea: {
            width: '100%',
            maxWidth: '55rem',
            margin: 'auto',
        },
        large: {
            position: 'relative',
            top: '-2rem',
            // left: 20,
            width: '8rem',
            height: '8rem',
        },
        tabsContainer: {
            boxShadow: 'none',
            maxWidth: '45rem',
        },
        tabPanel: {
            color: '#000',
            fontColor: '#000',
            maxWidth: '50rem',
            margin: 'auto',
        },
        messageTabPanel: {
            color: '#000',
            fontColor: '#000',
            maxWidth: '80rem',
            margin: 'auto',
        },
    }),
);

interface VolunteerProfileProps {
    totalUnreadChats: number;
}

const VolunteerProfile = (props: VolunteerProfileProps) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = React.useState(0);
    const { userData: volunteerDetails } = useContext(UserContext);

    const [isEditVolunteer, setIsEditVolunteer] = useState(false);
    const [volunteerLocation, setVolunteerLocation] = useState<{
        label: string;
        value: Record<string, unknown>;
    }>({ label: 'N/A', value: {} });

    const { mobileViewBig } = useContext(CommonDataContext);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleUpdateVolunteerLocation = (location: {
        label: string;
        value: Record<string, unknown>;
    }) => {
        setVolunteerLocation(location);
    };

    const handleUpdateVolunteer = async (orgData: {
        company: string;
        location: Location;
    }) => {
        await firebase.updateCompany(orgData);
    };

    useEffect(() => {
        if (volunteerDetails?.location) {
            setVolunteerLocation({
                label: `${volunteerDetails.location?.address?.label}`,
                value: volunteerDetails.location?.address?.value ?? {},
            });
        }
    }, [volunteerDetails]);

    return (
        <ProjectsContextProvider>
            <div className="g_whitebg">
                <div className="g_profile_banner">
                    {isEditVolunteer ? (
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            className="edit-volunteer-container"
                        >
                            <Box padding={'8px'}>
                                {volunteerDetails?.account_type !==
                                'Individual Partners' ? (
                                    <Typography align="center" variant="h4">
                                        {volunteerDetails?.company}
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Box display={'flex'} justifyContent={'center'}>
                                <Box padding="8px" width="50%">
                                    <GoogleLocation
                                        selectProps={{
                                            isClearable: true,
                                            value: volunteerLocation,
                                            onChange: (val: {
                                                label: string;
                                                value: Record<string, unknown>;
                                            }) => {
                                                handleUpdateVolunteerLocation(
                                                    val,
                                                );
                                            },
                                            placeholder: 'Location',
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Tooltip title="Cancel">
                                        <IconButton
                                            onClick={() => {
                                                setIsEditVolunteer(false);
                                            }}
                                        >
                                            <CancelOutlined
                                                fontSize="medium"
                                                className="g_whitetext"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save">
                                        <IconButton
                                            onClick={() => {
                                                if (volunteerLocation) {
                                                    handleUpdateVolunteer({
                                                        company:
                                                            volunteerDetails?.company!,
                                                        location: {
                                                            address:
                                                                volunteerLocation,
                                                            latitude: 0,
                                                            longitude: 0,
                                                        },
                                                    });
                                                } else {
                                                    enqueueSnackbar(
                                                        'Location cannot be empty!',
                                                        {
                                                            key: 'Empty volunteer location data',
                                                            variant: 'error',
                                                        },
                                                    );
                                                    setVolunteerLocation({
                                                        label: `${volunteerDetails?.location?.address?.label}`,
                                                        value:
                                                            volunteerDetails
                                                                ?.location
                                                                ?.address
                                                                ?.value ?? {},
                                                    });
                                                }
                                                setIsEditVolunteer(false);
                                            }}
                                        >
                                            <Check
                                                fontSize="medium"
                                                className="g_whitetext"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {volunteerDetails?.account_type !==
                                'Individual Partners' && (
                                <Typography align="center" variant="h4">
                                    {volunteerDetails?.company}
                                </Typography>
                            )}
                            <Box padding={'8px'}>
                                <Typography align="center" variant="h4">
                                    {`${volunteerDetails?.first_name} ${volunteerDetails?.last_name}`}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography className="jss13 MuiTypography-body1">
                                    {volunteerLocation?.label}
                                </Typography>
                                <Tooltip title="edit">
                                    <IconButton
                                        onClick={() => setIsEditVolunteer(true)}
                                    >
                                        <Edit
                                            fontSize="medium"
                                            className="g_whitetext"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </div>
                <div className={classes.root}>
                    <div className={classes.topArea}>
                        {!mobileViewBig && (
                            <div className={classes.large}>
                                <ProfilePicture size="profile" />
                            </div>
                        )}
                        <AppBar
                            className={classes.tabsContainer}
                            color="transparent"
                            position="static"
                        >
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                variant="scrollable"
                            >
                                <Tab label="My Information" {...a11yProps(0)} />
                                <Tab label="My Projects" {...a11yProps(1)} />
                                <Tab label="Inbox" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                    </div>
                    <div className={classes.bottomArea}>
                        <TabPanel
                            className={classes.tabPanel}
                            value={value}
                            index={0}
                        >
                            <MyInformation />
                        </TabPanel>
                        <TabPanel
                            className={classes.tabPanel}
                            value={value}
                            index={1}
                        >
                            <VolunteerProjects />
                        </TabPanel>
                        <TabPanel
                            className={classes.messageTabPanel}
                            value={value}
                            index={2}
                        >
                            <Inbox totalUnreadChats={props.totalUnreadChats} />
                        </TabPanel>
                    </div>
                </div>
            </div>
        </ProjectsContextProvider>
    );
};

export default VolunteerProfile;
