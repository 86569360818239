import { IconButton, Tooltip } from '@material-ui/core';
import { SocialMedias } from 'const';
import React from 'react';
import {
    Twitter,
    Public,
    Instagram,
    Facebook,
    LinkedIn,
    InsertLinkRounded,
    YouTube,
} from '@material-ui/icons';

interface SocialIconProps {
    url: string;
    name: string;
    isBlack?: boolean;
    isDisabled?: boolean;
}

const SocialIcon = (props: SocialIconProps) => {
    return (
        <Tooltip title={props.name}>
            <IconButton
                disabled={props.isDisabled}
                onClick={() => {
                    window.open(
                        props.url.match(/^http[s]?:\/\//)
                            ? props.url
                            : 'https://' + props.url,
                        '_blank',
                    );
                }}
            >
                {props.name === SocialMedias.FACEBOOK ? (
                    <Facebook
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : props.name === SocialMedias.TWITTER ? (
                    <Twitter
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : props.name === SocialMedias.INSTAGRAM ? (
                    <Instagram
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : props.name === SocialMedias.LINKEDIN ? (
                    <LinkedIn
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : props.name === SocialMedias.ORGANIZATION_WEBSITE ? (
                    <InsertLinkRounded
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : props.name === SocialMedias.YOUTUBE ? (
                    <YouTube
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                ) : (
                    <Public
                        fontSize="large"
                        style={{ color: props.isBlack ? '#404145' : 'white' }}
                    />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default SocialIcon;
