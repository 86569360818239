import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { AppRoutes } from 'App';
import { UserContext } from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ReviewType } from 'types';
import firebase from 'utils/auth';
import ReviewCard from './ReviewCard';

interface ReviewesProps {
    reviewes: Array<ReviewType>;
    organizationId: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const Reviewes = (props: ReviewesProps) => {
    const { isChecking, isLoggedIn } = useContext(UserContext);

    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewBody, setReviewBody] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    const theme = useTheme();
    const b_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const handleCreateReview = () => {
        if (!isChecking && isLoggedIn) {
            setIsCreateDialogOpen(true);
        } else {
            setIsLoginDialogOpen(true);
        }
    };

    const handleSubmitReview = async () => {
        setIsLoading(true);
        let returnData: any = await firebase.createReview(
            reviewTitle,
            reviewBody,
            props.organizationId,
        );
        setIsLoading(false);
        setReviewTitle('');
        setReviewBody('');
        setIsCreateDialogOpen(false);

        if (returnData.status === 'ok') {
            enqueueSnackbar('Review Submited!', {
                variant: 'success',
            });
        }
    };

    return (
        <Box width="100%" className={classes.root}>
            <Box
                width="100%"
                display="flex"
                flexDirection={b_sm ? 'row' : 'column'}
                justifyContent="space-between"
                mb="2rem"
            >
                <Box display="flex">
                    <Box mr="1rem">
                        <Typography className="g_mauto" variant="h6">
                            Reviews
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        disabled={isChecking}
                        onClick={handleCreateReview}
                    >
                        Create
                    </Button>
                </Box>
                <div className="g_row">
                    <div className="g_mr1">
                        <Typography variant="body1">Sort By </Typography>
                    </div>
                    <Select value={'Most Recent'}>
                        <MenuItem value="Most Recent">Most Recent</MenuItem>
                    </Select>
                </div>
            </Box>
            {props?.reviewes?.length === 0 && (
                <Typography align="center">No Reviewes</Typography>
            )}
            {props?.reviewes?.map((item: ReviewType, index: number) => (
                <ReviewCard
                    reviewerName={item.reviewer_name}
                    createdOn={item.created_on.toDate()}
                    title={item.title}
                    review={item.review}
                    userId={item.reviewer_id}
                />
            ))}

            <Dialog
                open={isLoginDialogOpen}
                onClose={() => setIsLoginDialogOpen(false)}
            >
                <DialogTitle>Please login!</DialogTitle>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsLoginDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            history.push(AppRoutes.Login);
                        }}
                    >
                        Go To Login Page
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                maxWidth="sm"
                fullWidth
                fullScreen={!b_sm}
            >
                <DialogTitle>Create A Review!</DialogTitle>
                <DialogContent>
                    <Box mt="1rem">
                        <Typography>Title:</Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={reviewTitle}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setReviewTitle(e.target.value)
                            }
                        />
                    </Box>
                    <Box mt="2rem">
                        <Typography>Review:</Typography>
                        <TextField
                            multiline
                            minRows={6}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={reviewBody}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setReviewBody(e.target.value)
                            }
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsCreateDialogOpen(false)}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitReview}
                        disabled={isLoading}
                    >
                        Post
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Reviewes;
