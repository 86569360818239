import React from 'react';
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import TermsAndConditionDoc from '../../assets/n-vest-partners-terms-and-conditions.pdf';

const TermsAndCondition = () => {
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
            <div
                style={{
                    height: '50rem',
                    width: '80%',
                    margin: '1rem auto',
                }}
            >
                <Viewer
                    fileUrl={TermsAndConditionDoc}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </div>
        </Worker>
    );
};
export default TermsAndCondition;
