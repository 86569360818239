import React from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import MapMarker from 'components/ProjectCard2/MapMarker';
import { OrganizationType, ProjectType } from 'types';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    rootDialog: {
        '& > .MuiBackdrop-root': { top: `calc(100vh - 390px) !important` },
    },
    impact: {
        fontWeight: 'bold',
    },
    labelMap: {
        fontWeight: 'bold',
    },
    projectOverview: {
        fontWeight: 'bold',
        marginTop: '30px',
    },
    projectNeeds: {
        fontWeight: 'bold',
        marginTop: '30px',
    },
    backDrop: {
        filter: 'blur(3px)',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
    },
}));
type ProjectDetailTabProps = {
    isLoggedIn: boolean;
    data: ProjectType | undefined;
    organizationData: OrganizationType | null;
};

function ProjectDetailTab(props: ProjectDetailTabProps) {
    const { data, isLoggedIn, organizationData } = props;
    const classes = useStyles();
    const history = useHistory();
    const place = data?.location?.address?.label.split(',')[0];
    const country =
        data?.location?.address?.label.split(',')[
            data?.location?.address?.label.split(',').length - 1
        ];

    const handleSeeFullList = () => {
        history.push(`/organization/${organizationData?.id}`);
    };

    return (
        <>
            {!isLoggedIn && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Please login to see the details </p>
                    </div>
                </div>
            )}

            <Grid container className={!isLoggedIn ? classes.backDrop : ''}>
                <Grid item xs={8}>
                    <Grid container>
                        {/* <Grid item xs={12}>
                            <Typography variant="h4" className={classes.impact}>
                                Websites
                            </Typography>
                            <Box>
                                <ul>
                                    {organizationData?.socials?.map(
                                        (social: SocialMediaType) => {
                                            return (
                                                <li key={social.url}>
                                                    <Box display={'flex'}>
                                                        <Typography variant="subtitle1">
                                                            {social.type.toUpperCase()}
                                                            :
                                                        </Typography>
                                                        <Link
                                                            className={
                                                                classes.link
                                                            }
                                                            href={social.url}
                                                            underline="hover"
                                                            target="_blank"
                                                        >
                                                            &nbsp;{social.url}
                                                        </Link>
                                                    </Box>
                                                </li>
                                            );
                                        },
                                    )}
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.impact}>
                                Organization overview
                            </Typography>
                            <Box padding={'10px 0px'}>
                                <AboutOrg organizationData={organizationData} />
                            </Box>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={classes.projectOverview}
                            >
                                Project Overview
                            </Typography>
                            <Box>
                                <Typography variant="subtitle1">
                                    {data?.description}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={classes.projectNeeds}
                            >
                                Experience Required
                            </Typography>
                            <Box>
                                <ul>
                                    {data?.requirements?.map((requirement) => {
                                        return (
                                            <li key={requirement}>
                                                {requirement}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {' '}
                            <Typography
                                variant="h4"
                                className={classes.projectNeeds}
                            >
                                Want to know about our organization ?
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-start"
                                width={'100%'}
                                padding="10px"
                            >
                                <Button
                                    onClick={handleSeeFullList}
                                    className="g_primary_btn"
                                    color="primary"
                                    variant="contained"
                                >
                                    Click here
                                </Button>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="h4" className={classes.impact}>
                                Impact
                            </Typography>
                            <Box>
                                <ul>
                                    <li>
                                        DCI Provides food, education and clothes
                                        to children struggling with poverty
                                    </li>
                                    <li>
                                        You help volunteering with the Community
                                        Center Camp will save us N10,087 per
                                        week.
                                    </li>
                                    <li>
                                        This money will be put towards the
                                        school uniforms, shoes and socks for the
                                        need children in the villages nearby.
                                    </li>
                                </ul>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.labelMap}>
                        {`Map of ${place}, ${country}`}
                    </Typography>
                    <div style={{ height: '30vh', width: '70%' }}>
                        <GoogleMapReact
                            yesIWantToUseGoogleMapApiInternals={true}
                            bootstrapURLKeys={{
                                key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
                            }}
                            defaultCenter={{
                                lat: data?.location
                                    ? data?.location.latitude
                                    : 0,
                                lng: data?.location
                                    ? data?.location.longitude
                                    : 0,
                            }}
                            defaultZoom={15}
                        >
                            <MapMarker
                                lat={
                                    data?.location ? data?.location.latitude : 0
                                }
                                lng={
                                    data?.location
                                        ? data?.location.longitude
                                        : 0
                                }
                                text="H"
                            />
                        </GoogleMapReact>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default ProjectDetailTab;
