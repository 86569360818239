import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import './FeaturedCard.scss';
import LinesEllipsis from 'react-lines-ellipsis';
import LocationIcon from '@material-ui/icons/RoomTwoTone';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { useHistory } from 'react-router';

export interface FeaturedCardProps {
    id: string;
    name: string;
    description: string;
    location: string;
    image_url: string;
    skills?: string[];
    requirements?: string[];
    variant?: 'organization' | 'project';
    documentId?: string;
    match?: { params: { id: string } };
}

const FeaturedCard = (props: FeaturedCardProps) => {
    const [elevation, setElevation] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const history = useHistory();

    return (
        <Card
            elevation={elevation}
            onMouseEnter={(e) => {
                setElevation(3);
                if (props.variant === 'project') {
                    setShowOverlay(true);
                }
            }}
            onMouseLeave={(e) => {
                setElevation(0);
                if (props.variant === 'project') {
                    setShowOverlay(false);
                }
            }}
            onClick={() => {
                if (props.variant === 'organization') {
                    history.push(`/organization/${props.id}`);
                } else {
                    props.variant === 'project'
                        ? history.push(`/${props.documentId}/project-details`)
                        : history.push(`/${props.id}/project-details`);
                }
            }}
            className="fc_item"
        >
            <CardActionArea>
                <CardMedia
                    className="fc_item_image"
                    image={props.image_url}
                    title="Contemplative Reptile"
                />
                <div
                    className={`fc_image_overlay ${
                        showOverlay ? '' : 'fc_hide'
                    }`}
                >
                    <div className="fc_image_overlay_section">
                        <p className="fc_image_overlay_heading">Skills</p>
                        {props.skills?.slice(0, 3).map((skill, index) => (
                            <p
                                key={`${skill}${index}${Math.random()}`}
                                className="fc_image_overlay_point"
                            >
                                {skill}
                            </p>
                        ))}
                    </div>
                    <div className="fc_image_overlay_section">
                        <p className="fc_image_overlay_heading">Requirements</p>
                        {props.requirements?.slice(0, 3).map((requirement) => (
                            <p className="fc_image_overlay_point">
                                {requirement}
                            </p>
                        ))}
                        <Button
                            href="#"
                            component="a"
                            style={{ color: 'blue', padding: '10px 0px' }}
                        >
                            Read More...
                        </Button>
                    </div>
                </div>
                <CardContent>
                    <Typography variant="h6" component="h2">
                        {props.name}
                    </Typography>
                    <div className="fc_container">
                        <div className="fc_location_container g_mt1 g_mb1">
                            <LocationIcon
                                style={{ fontSize: '1rem' }}
                                className="two_shaded_icon"
                            />
                            <p className="fc_location">{props.location}</p>
                        </div>
                        <div className="fc_save_container g_mt1 g_mb1">
                            <FavoriteBorder
                                style={{ fontSize: '1rem' }}
                                className="two_shaded_icon"
                            />
                            <p className="fc_save">Save for later</p>
                        </div>
                    </div>
                    <LinesEllipsis
                        className="fc_body"
                        text={props.description}
                        maxLine="5"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                    {/* <Typography className="fc_body" variant="body2">{props.description}</Typography> */}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default FeaturedCard;
