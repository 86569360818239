import {
    Box,
    Button,
    Dialog,
    IconButton,
    makeStyles,
    TextField,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import React, {
    ChangeEvent,
    Fragment,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ReplyType } from 'types';
import { Send, Close, Delete } from '@material-ui/icons';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TimeAgo from 'react-timeago';

interface ChatPopupProps {
    isOpen: boolean;
    onClose: () => void;
    replies: Array<ReplyType>;
    onCreateReply: (reply: string) => void;
    otherUser: { userAvatar: string; userName: string };
    isSendingMessage: boolean;
    userId: string;
    deleteMessage: () => void;
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '95vw',
        height: '90vh',
        maxWidth: '40rem',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '1.2rem',
        fontWeight: 700,
    },
    reply: {
        padding: '.6rem',
        margin: '.4rem',
        background: 'lightblue',
        borderRadius: '.2rem',
        maxWidth: '30rem',
        width: '100%',
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
    },
    otherReply: {
        padding: '.6rem',
        margin: '.4rem',
        background: 'lightgray',
        borderRadius: '.2rem',
        width: '100%',
        maxWidth: '30rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    chatArea: {
        flex: 1,
        border: 'solid lightgray .1rem',
        borderRadius: '.2rem',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        marginBottom: '1rem',
    },
    row: {
        display: 'flex',
    },
    confirmDeleteMessageText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    time: {
        color: 'gray',
        fontSize: '.6rem',
    },
    deleteBtn: {
        width: '50%',
    },
    deleteBtnActions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

const ChatPopup = (props: ChatPopupProps) => {
    const classes = useStyles();
    const [confirmDeleteMessage, setConfirmDeleteMessage] =
        useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const chatAreaRef = useRef<any>();

    useEffect(() => {
        if (props.replies && chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [props.replies, chatAreaRef]);

    const createReply = () => {
        if (text.trim() !== '') {
            props.onCreateReply(text);
            setText('');
        }
    };

    return (
        <Dialog
            onClose={props.onClose}
            open={props.isOpen}
            classes={{
                paper: classes.container,
            }}
        >
            <div className={classes.header}>
                <p className={classes.title}>{props.otherUser.userName}</p>
                {props.replies.length > 1 ? (
                    <Button
                        startIcon={<Delete color="error" />}
                        className={classes.deleteBtn}
                        onClick={() => {
                            setConfirmDeleteMessage(true);
                        }}
                    >
                        Delete Chat
                    </Button>
                ) : (
                    <></>
                )}

                <IconButton onClick={props.onClose}>
                    <Close />
                </IconButton>
            </div>

            {confirmDeleteMessage ? (
                <>
                    <Box className={classes.confirmDeleteMessageText}>
                        <Typography
                            variant="h3"
                            color="textSecondary"
                            component="p"
                        >
                            Are you sure you want to delete this thread ?
                        </Typography>
                        <Box className={classes.deleteBtnActions}>
                            <Button
                                startIcon={<DoneAllIcon />}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    props.deleteMessage();
                                    setConfirmDeleteMessage(false);
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                startIcon={<ThumbDownIcon />}
                                variant="contained"
                                color="default"
                                onClick={() => setConfirmDeleteMessage(false)}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <div ref={chatAreaRef} className={classes.chatArea}>
                        {props.replies.map((reply) => (
                            <Fragment key={reply.id}>
                                {reply.text ? (
                                    <div
                                        className={
                                            props.userId === reply.created_by
                                                ? classes.reply
                                                : classes.otherReply
                                        }
                                    >
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {reply.text}
                                        </Typography>
                                        <TimeAgo
                                            date={reply.created_at}
                                            className={classes.time}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Fragment>
                        ))}
                    </div>
                    <div className={classes.row}>
                        <TextField
                            value={text}
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setText(e.target.value)
                            }
                        />
                        <Button
                            startIcon={props.isSendingMessage ? null : <Send />}
                            variant="contained"
                            color="primary"
                            onClick={createReply}
                            disabled={props.isSendingMessage}
                        >
                            {props.isSendingMessage ? (
                                <CircularProgress size={26} />
                            ) : (
                                'Send'
                            )}
                        </Button>
                    </div>
                </>
            )}
        </Dialog>
    );
};

export default ChatPopup;
