import { ApprovalContext } from 'contexts/ApprovalContext';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button, Typography, Grid, Chip } from '@material-ui/core';
import firebase from '../../utils/auth';
import { ApprovalType } from 'types';

interface Column {
    id: 'volunteerName' | 'projectName' | 'status' | 'projectId';
    label: string;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    format?: (row: ApprovalType) => JSX.Element;
}

const setColor = (
    value: string,
): 'primary' | 'secondary' | 'default' | undefined => {
    switch (value) {
        case 'approved':
            return 'primary';
        case 'rejected':
            return 'secondary';
        case 'pending':
            return 'default';
        default:
            return undefined;
    }
};

const handleApproval = (
    status: string,
    volunteerId: string,
    organizationId: string,
    projectId: string,
) => {
    firebase.updateVolunteerRequest(
        status,
        volunteerId,
        organizationId,
        projectId,
    );
};

const columns: Column[] = [
    {
        id: 'projectName',
        label: 'Request details',
        minWidth: 170,
        align: 'left',
        format: (row: ApprovalType) => {
            return (
                <Typography variant="inherit">
                    <b>{row.volunteerName}</b> wants to join your organization
                    in &nbsp;
                    <b>{row.projectName}</b> project.
                </Typography>
            );
        },
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 170,
        align: 'center',
        format: (row: ApprovalType) => {
            return (
                <Chip
                    key={row.projectId}
                    color={setColor(row.status)}
                    variant="default"
                    label={row.status}
                />
            );
        },
    },
    {
        id: 'projectId',
        label: 'Action',
        minWidth: 170,
        align: 'center',
        format: (row: ApprovalType) => {
            const { status, projectId, volunteerId, organizationId } = row;
            return status === 'Pending' ? (
                <Grid
                    key={projectId}
                    container
                    spacing={2}
                    direction="row"
                    style={{ flexWrap: 'nowrap', flexFlow: 'row' }}
                >
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color={'primary'}
                            size={'small'}
                            onClick={() =>
                                handleApproval(
                                    'Approved',
                                    volunteerId,
                                    organizationId,
                                    projectId,
                                )
                            }
                        >
                            {'Approve'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color={'secondary'}
                            size={'small'}
                            onClick={() =>
                                handleApproval(
                                    'Rejected',
                                    volunteerId,
                                    organizationId,
                                    projectId,
                                )
                            }
                        >
                            {'Reject'}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Typography variant="inherit">No action required</Typography>
            );
        },
    },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const Approval = () => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { approvals } = useContext(ApprovalContext);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return approvals.length ? (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {approvals
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.projectId}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.format &&
                                                    typeof value === 'string'
                                                        ? column.format(row)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={approvals.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    ) : (
        <Typography variant="inherit">
            There are no pending approvals.
        </Typography>
    );
};
export default Approval;
