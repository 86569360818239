export const FIREBASE_APIKEY = process.env.REACT_APP_API_KEY;
export const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const FIREBASE_DATABASEURL = process.env.REACT_APP_DATABASE_URL;
export const FIREBASE_PROJECTID = process.env.REACT_APP_PROJECT_ID;
export const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const FIREBASE_MESSAGINGSENDERID =
    process.env.REACT_APP_MESSAGING_SENDER_ID;
export const FIREBASE_APPID = process.env.REACT_APP_APP_ID;
export const REACT_APP_FCM_SERVER_KEY = process.env.REACT_APP_FCM_SERVER_KEY;

export const ACCOUNT_TYPES: string[] = [
    'Individual Partners',
    'Corporate Partner',
    'Academic Institution',
    'Organization',
];
export type POSSIBLE_ACCOUNT_TYPES =
    | 'Individual Partners'
    | 'Corporate Partner'
    | 'Academic Institution'
    | 'Organization';

export const SocialMedias = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
    LINKEDIN: 'linkedin',
    YOUTUBE: 'youtube',
    ORGANIZATION_WEBSITE: 'organization_website',
};

export const ALGOLIA_APP_ID = '8L3TU45OIS';
export const ALGOLIA_SEARCH_KEY = '3c9c30a5fb9384277842b76fa5efe3eb';
