import React, { FC, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { UserContext } from '../../contexts/UserContext';

interface ProfilePicturePropTypes {
    size: 'profile' | 'profileMobile' | 'header' | 'headerMobile';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profile: {
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        profileMobile: {
            width: theme.spacing(12),
            height: theme.spacing(12),
        },
        header: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        headerMobile: {
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    }),
);

const ProfilePicture: FC<ProfilePicturePropTypes> = (
    props: ProfilePicturePropTypes,
) => {
    const classes = useStyles();

    const { userData } = useContext(UserContext);

    return (
        <Avatar
            className={classes[props.size]}
            alt="User Avatar"
            src={userData ? userData.avatar : ''}
        />
    );
};

export default ProfilePicture;
