import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import logo from '../../images/N-Vest-Logo.svg';
import './footer.scss';
import { Typography } from '@material-ui/core';
import { AppRoutes } from '../../App';

const Footer = () => {
    return (
        <div className="footer_comp">
            <div className="footer_comp_logo">
                <a href="/">
                    <img src={logo} alt="" />
                </a>
            </div>
            <div className="footer_comp_links">
                <div className="footer_comp_links_sec">
                    <p className="footer_links_main">Company</p>
                    <Link className="footer_links" to={AppRoutes.AboutNvest}>
                        About Us
                    </Link>
                    <Link
                        className="footer_links"
                        to={AppRoutes.successStories}
                    >
                        Success Stories
                    </Link>
                    <Link className="footer_links" to={AppRoutes.careers}>
                        Careers
                    </Link>
                    <Link className="footer_links" to={AppRoutes.blog}>
                        Blog
                    </Link>
                    <Link
                        className="footer_links"
                        to={AppRoutes.PrivacyPolicy}
                        // target="_blank"
                    >
                        Privacy Policy
                    </Link>
                </div>
                <div className="footer_comp_links_sec">
                    <p className="footer_links_main">Stakeholders</p>
                    <Link className="footer_links" to="">
                        Organizations
                    </Link>
                    <Link className="footer_links" to="">
                        Individual Partners
                    </Link>
                    <Link className="footer_links" to="">
                        Academic Institutions
                    </Link>
                    <Link className="footer_links" to="">
                        Corporate Partners
                    </Link>
                    <Link
                        className="footer_links"
                        to={AppRoutes.TermsAndCondition}
                        // target="_blank"
                    >
                        Terms and Conditions
                    </Link>
                </div>
                <div className="footer_comp_links_sec">
                    <p className="footer_links_main">Resources</p>
                    <Link className="footer_links" to={AppRoutes.contactUs}>
                        Contact Us
                    </Link>
                    <Link className="footer_links" to={AppRoutes.donation}>
                        Donation
                    </Link>
                    <Link className="footer_links" to={AppRoutes.faq}>
                        FAQ
                    </Link>
                    <Link className="footer_links" to={AppRoutes.newsletter}>
                        Newsletter
                    </Link>
                </div>
            </div>
            <div className="footer_comp_actions">
                <div className="footer_comp_donate_btn">
                    <Link className="footer_donation" to={AppRoutes.donation}>
                        <Button
                            className="g_primary_btn"
                            variant="contained"
                            color="secondary"
                        >
                            Donate
                        </Button>
                    </Link>
                </div>
                <div className="footer_comp_donate_text">
                    <Typography variant="caption">
                        We are a nonprofit organization. Your tax-deductible
                        donations help us connect good people with good causes.
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Footer;
