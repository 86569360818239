import { ApprovalType } from 'types';

export const getProjectActivityType = (id: number | undefined) => {
    switch (id) {
        case 1:
            return '1-2 hours/week';
        case 2:
            return '3-4 hours/week';
        case 3:
            return '5-6 hours/week';
        case 4:
            return '7 Hours+/week';
        default:
            break;
    }
};

export const getProjectType = (id: number | undefined) => {
    switch (id) {
        case 1:
            return 'In Project';
        case 2:
            return 'Online';
        case 3:
            return 'Both';
        default:
            break;
    }
};

// Helper function to get the work description based on the work_type
export const getWorkDescription = (work_type: number | undefined) => {
    switch (work_type) {
        case 1:
            return 'Remote';
        case 2:
            return 'In-Person';
        default:
            return 'Remote and In-Person';
    }
};

export const getRequestStatus = (
    volunteerRequest: ApprovalType[],
    userId: string,
    organizationId: string,
) => {
    const requests = volunteerRequest.filter(
        (req) =>
            req.volunteerId === userId && req.organizationId === organizationId,
    );
    return requests[requests.length - 1]?.status;
};

export const getProjectRequestStatusLabel = (
    volunteerRequest: ApprovalType[],
    userId: string,
    organizationId: string,
) => {
    const status = getRequestStatus(volunteerRequest, userId, organizationId);
    switch (status) {
        case 'Approved':
            return 'Congrats. You are part of the team';
        case 'Pending':
            return 'Request Pending';
        case 'Rejected':
            return 'Join the team';

        default:
            return 'Join the team';
    }
};

export const isObjectEmpty = <T extends Record<string, string>>(
    obj: Partial<T>,
): boolean => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== '') {
            return false;
        }
    }
    return true;
};
