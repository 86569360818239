import { POSSIBLE_ACCOUNT_TYPES } from 'const';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UserType } from 'types';
import firebase from 'utils/auth';

interface UserContextType {
    accountType: POSSIBLE_ACCOUNT_TYPES | null;
    isLoggedIn: boolean;
    logout: () => void;
    isChecking: boolean;
    userData: UserType | null;
    setIsChecking: (x: boolean) => void;
}

export const UserContext = createContext<UserContextType>({
    accountType: null,
    isLoggedIn: false,
    isChecking: true,
    userData: null,
    logout: () => {},
    setIsChecking: (x: boolean) => {},
});

const UserContextProvider = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isChecking, setIsChecking] = useState<boolean>(true);
    const [accountType, setAccountType] =
        useState<POSSIBLE_ACCOUNT_TYPES | null>(null);
    const [userData, setUserData] = useState<UserType | null>(null);

    const history = useHistory();

    const logout = useCallback(async () => {
        await firebase.logout();
        setIsLoggedIn(false);
        //TODO: Need to find a good fix for this
        localStorage.removeItem('isLoggedIn');
        setUserData(null);
        history.push('/login');
    }, [history, setIsLoggedIn]);

    useEffect(() => {
        let unsub = () => {
            return;
        };
        firebase.auth.onAuthStateChanged(async function (user) {
            if (user) {
                // console.log(user);
                setIsLoggedIn(true);
                try {
                    // let userData = await firebase.getUserData();
                    unsub = firebase.db
                        .collection('users')
                        .doc(user.uid)
                        .onSnapshot(async (snapshot) => {
                            let userData = snapshot.data() as UserType;
                            if (userData) {
                                userData.id = snapshot.id;
                                if (
                                    userData.account_type ===
                                    'Individual Partners'
                                ) {
                                    const relatedOrganization =
                                        await firebase.getOrganizationsByVolunteerId(
                                            userData.id,
                                        );
                                    userData.organization_id =
                                        relatedOrganization;
                                }
                                setUserData(userData);
                                setAccountType(userData.account_type);
                            }
                            setIsChecking(false);
                        });
                } catch (e) {
                    console.log(e);
                    setIsChecking(false);
                }
            } else {
                setIsLoggedIn(false);
                setIsChecking(false);
            }
        });
        return unsub;
    }, []);

    return (
        <UserContext.Provider
            value={{
                accountType,
                isLoggedIn,
                logout,
                isChecking,
                userData,
                setIsChecking,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
