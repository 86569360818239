import React, { useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import './BasicInfoForm.scss';
import { SignupContext } from '../../contexts/SignupContext';
import { DatePicker } from '@material-ui/pickers';
import { ACCOUNT_TYPES } from '../../const';
import { AppRoutes } from '../../App';

const BasicInfoForm: React.FC = () => {
    const { step1Data, setStep1Data } = useContext(SignupContext);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (step1Data) {
            if (step1Data.application_type) {
                setShowForm(true);
            } else {
                setShowForm(false);
            }
        }
    }, [step1Data]);

    const handleApplicationTypeChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        event.persist();
        setStep1Data((pre: any) => ({
            ...pre,
            company_name: '',
            application_type: event.target.value as string,
        }));
    };

    // const handleCompanyNameChange = (
    //     event: React.ChangeEvent<{ value: unknown }>,
    // ) => {
    //     event.persist();
    //     setStep1Data((pre: any) => ({
    //         ...pre,
    //         company_name: event.target.value as string,
    //     }));
    // };

    const handleTnCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setTnCCheck(event.target.checked);
        event.persist();
        setStep1Data((pre: any) => ({
            ...pre,
            agreed: event.target.checked,
        }));
    };

    const valueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setStep1Data((pre: any) => ({
            ...pre,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <Container className="root">
            <div>
                <form noValidate autoComplete="off">
                    {/* <div className="row_centered">
            <p className="info_text" >For Individual Partners applicants, If you choose to select a company name from the drop down,  please note that you will have the opportunity to only view opportunities that the selected company has partnered with N-Vest Africa.  Ensure to leave this option blank if you are not choosing to associate your application with a selected company and would like to view all possible opportunities that match your profile.</p>
          </div> */}
                    <div className="row_centered">
                        <FormControl
                            variant="outlined"
                            className="form-control"
                        >
                            <InputLabel
                                id="application-type-label"
                                className="form-control-label"
                            >
                                Application Type
                            </InputLabel>
                            <Select
                                labelId="application-type-label"
                                id="demo-simple-select-outlined"
                                onChange={handleApplicationTypeChange}
                                value={step1Data.application_type}
                                label="Application Type"
                                className="form-control-select"
                            >
                                <MenuItem disabled value="">
                                    <em>Select one</em>
                                </MenuItem>
                                <MenuItem value="Individual Partners">
                                    Individual Partners
                                </MenuItem>
                                <MenuItem value="Organization">
                                    Organization
                                </MenuItem>
                                <MenuItem value="Corporate Partner">
                                    Corporate Partner
                                </MenuItem>
                                <MenuItem value="Academic Institution">
                                    Academic Institution
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {showForm &&
                        step1Data.application_type !== ACCOUNT_TYPES[0] && (
                            <div className="row_centered">
                                <p className="info_text">
                                    As the administrator, please complete this
                                    application for your institution and serve
                                    as the point of contact.
                                </p>
                            </div>
                        )}

                    {showForm && (
                        <>
                            <div className="row">
                                {step1Data.application_type ===
                                ACCOUNT_TYPES[0] ? (
                                    // <FormControl
                                    //     variant="outlined"
                                    //     className="form-control"
                                    // >
                                    //     <InputLabel
                                    //         id="company-name-label"
                                    //         className="form-control-label"
                                    //     >
                                    //         Company Name*
                                    //     </InputLabel>
                                    //     <Select
                                    //         required
                                    //         labelId="company-name"
                                    //         id="demo-simple-select-outlined"
                                    //         onChange={handleCompanyNameChange}
                                    //         value={step1Data.company_name}
                                    //         label="Company Name"
                                    //         className="form-control-select"
                                    //     >
                                    //         <MenuItem value="">
                                    //             <em>No Company</em>
                                    //         </MenuItem>
                                    //         {step1Data.approved_companies_list &&
                                    //             step1Data.approved_companies_list.map(
                                    //                 (
                                    //                     val: string,
                                    //                     index: number,
                                    //                 ) => (
                                    //                     <MenuItem
                                    //                         key={index}
                                    //                         value={val}
                                    //                     >
                                    //                         {val}
                                    //                     </MenuItem>
                                    //                 ),
                                    //             )}
                                    //     </Select>
                                    // </FormControl>
                                    <></>
                                ) : (
                                    <TextField
                                        required
                                        id="companyName"
                                        label="Company Name"
                                        variant="outlined"
                                        className="form-field"
                                        name="company_name"
                                        value={step1Data.company_name}
                                        onChange={valueChanged}
                                    />
                                )}
                                <TextField
                                    required
                                    id="firstName"
                                    label={
                                        step1Data.application_type ===
                                        ACCOUNT_TYPES[0]
                                            ? 'First Name'
                                            : 'Admin First Name'
                                    }
                                    variant="outlined"
                                    className="form-field"
                                    name="first_name"
                                    value={step1Data.first_name}
                                    onChange={valueChanged}
                                />

                                {step1Data.application_type ===
                                    ACCOUNT_TYPES[0] && (
                                    <TextField
                                        required
                                        id="lastName"
                                        label={'Last Name'}
                                        variant="outlined"
                                        className="form-field"
                                        name="last_name"
                                        value={step1Data.last_name}
                                        onChange={valueChanged}
                                    />
                                )}
                            </div>

                            <div className="row">
                                {step1Data.application_type !==
                                    ACCOUNT_TYPES[0] && (
                                    <TextField
                                        required
                                        id="lastName"
                                        label={
                                            step1Data.application_type ===
                                            ACCOUNT_TYPES[0]
                                                ? 'Last Name'
                                                : 'Admin Last Name'
                                        }
                                        variant="outlined"
                                        className="form-field"
                                        name="last_name"
                                        value={step1Data.last_name}
                                        onChange={valueChanged}
                                    />
                                )}
                                <FormControl
                                    component="fieldset"
                                    className="form-control"
                                >
                                    <FormLabel
                                        component="legend"
                                        className="form-control-label"
                                    >
                                        Gender *
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        // name="gender"
                                        // value={gender}
                                        className="gender-group"
                                        // onChange={handleGenderUpdate}
                                        name="gender"
                                        value={step1Data.gender}
                                        onChange={valueChanged}
                                    >
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio />}
                                            label="Female"
                                        />
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio />}
                                            label="Male"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="row">
                                <TextField
                                    required
                                    id="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    className="form-field"
                                    name="phone_number"
                                    value={step1Data.phone_number}
                                    onChange={valueChanged}
                                />
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    autoComplete="email"
                                    className="form-field"
                                    name="email"
                                    value={step1Data.email}
                                    onChange={valueChanged}
                                />
                            </div>

                            <div className="row">
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    autoComplete="current-password"
                                    className="form-field"
                                    name="password"
                                    value={step1Data.password}
                                    onChange={valueChanged}
                                />
                                <TextField
                                    required
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type="password"
                                    autoComplete="current-password"
                                    className="form-field"
                                    name="conf_password"
                                    value={step1Data.conf_password}
                                    onChange={valueChanged}
                                />
                            </div>

                            {step1Data.application_type !==
                                ACCOUNT_TYPES[0] && (
                                <div className="row">
                                    <TextField
                                        required
                                        id="city"
                                        label="City"
                                        variant="outlined"
                                        type="text"
                                        className="form-field"
                                        name="city"
                                        value={step1Data.city}
                                        onChange={valueChanged}
                                    />
                                    <TextField
                                        required
                                        id="country"
                                        label="Country"
                                        variant="outlined"
                                        type="text"
                                        className="form-field"
                                        name="country"
                                        value={step1Data.country}
                                        onChange={valueChanged}
                                    />
                                </div>
                            )}

                            {step1Data.application_type ===
                                ACCOUNT_TYPES[0] && (
                                <div className="row">
                                    <DatePicker
                                        id="dateOfBirth"
                                        label="Date of Birth *"
                                        value={step1Data.date_time}
                                        onChange={(date: any) => {
                                            setStep1Data((pre: any) => ({
                                                ...pre,
                                                date_time: date,
                                            }));
                                        }}
                                        animateYearScrolling
                                        name="date_time"
                                        className="form-field"
                                        inputVariant="outlined"
                                        format="DD MMM, YYYY"
                                        disableFuture
                                        openTo="year"
                                        views={['year', 'month', 'date']}
                                    />
                                </div>
                            )}

                            <div className="form-footer">
                                <Typography
                                    className="info_text"
                                    variant="body2"
                                    gutterBottom
                                >
                                    Click
                                    <Link to={AppRoutes.PrivacyPolicy}>
                                        here 
                                    </Link>
                                    to read the N-Vest Privacy Policy
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={step1Data.agreed}
                                            onChange={handleTnCheck}
                                            name="tncCheck"
                                        />
                                    }
                                    label="I agree to the N-Vest Partners Privacy Policy and agree to the terms and conditions*"
                                />
                            </div>
                        </>
                    )}
                </form>
            </div>
        </Container>
    );
};
export default BasicInfoForm;
