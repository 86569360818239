import React, { createContext, useEffect, useState } from 'react';
import firebase from '../utils/auth';

export const SignupContext = createContext();

const SignupContextProvider = (props) => {
    const [step1Data, setStep1Data] = useState({
        application_type: null,
        company_name: '',
        first_name: '',
        last_name: '',
        date_time: new Date(),
        gender: '',
        phone_number: null, // default null
        email: '',
        password: '',
        conf_password: '',
        agreed: false,
        approved_companies_list: null,
        city: '',
        country: '',
    });

    const [step3DataReferences, setStep3DataReferences] = useState([
        {
            full_name: '',
            relationship: '',
            email: '',
            phone_number: '',
        },
    ]);

    const [step2Data, setStep2Data] = useState([
        {
            profession: '',
            license_number: '',
            email: '',
            linkedin_profile: '',
            website_link: '',
            about_organization: '',
            mission_and_goals: '',
        },
    ]);

    const [step3Data, setStep3Data] = useState('');
    const [step2File, setStep2File] = useState(null);

    const [isUploadingData, setIsUploadingData] = useState(false);

    useEffect(() => {
        const getList = async () => {
            let list = await firebase.getApprovedCompanyNames();
            setStep1Data((pre) => ({
                ...pre,
                approved_companies_list: list,
            }));
        };
        getList();
    }, []);

    return (
        <SignupContext.Provider
            value={{
                step2File,
                setStep2File,
                step1Data,
                setStep1Data,
                step2Data,
                setStep2Data,
                step3Data,
                step3DataReferences,
                setStep3DataReferences,
                setStep3Data,
                isUploadingData,
                setIsUploadingData,
            }}
        >
            {props.children}
        </SignupContext.Provider>
    );
};

export default SignupContextProvider;
