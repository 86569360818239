import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from 'const';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
const projectsIndex = searchClient.initIndex('projects');
const organizationsIndex = searchClient.initIndex('organizations');
const usersIndex = searchClient.initIndex('users');

export default function useAlgolia() {
    return { projectsIndex, organizationsIndex, usersIndex };
}
