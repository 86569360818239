import React from 'react';
import { Typography } from '@material-ui/core';
import FounderImg from '../../images/founder_img_sm.jpg';
import './FounderStory.scss';

const FounderStory: React.FC = () => {
    return (
        <div className="about_root">
            <div className="hwv_body g_wfull g_whitebg"></div>

            <div className="g_mt2">
                <div className="founderImgWrap g_mb2">
                    <img src={FounderImg} alt="hero img" className="teamImg" />
                </div>
                <Typography variant="h5" align="center" gutterBottom>
                    Our Founder's Story
                </Typography>
            </div>
            <div className="about_vision g_mt2">
                <Typography>
                    N-Vest Partners is inspired by my love for Black and African
                    communities, and desire to invest in sustainable ways.
                    During my graduate studies, I took the opportunity to use my
                    final research to research the concept of “Brain Gain” and
                    focus on the impacts of intentionally investing in local
                    groups. I was encouraged to see that the concept of
                    investment through volunteerism was something that the vast
                    majority of my 200 plus respondents were interested in.
                    However, the common theme presented in the responses was
                    that respondents were not willing to create opportunities to
                    do so but were willing to use their time, skills and
                    resources to support credible organizations. I continue to
                    be inspired by current impacts that local organizations have
                    on their communities. I have built a way that, rather than
                    fighting for resources for new initiatives, our work will be
                    committed to N-Vesting in the important work that is already
                    taking place, allowing for local organizations to have
                    stronger, larger, and long-lasting Impacts.
                    <br />
                    <br />
                    N-Vest Partners is the platform that allows partners to
                    invest in local groups that have great impacts in their
                    communities. I believe that there are several great groups
                    on ground doing great work, however there are multiple
                    barriers that restrict their level of impact. My desire,
                    through N-Vest Partners, is to eliminate barriers to
                    volunteerism , within local communities and internationally,
                    in addition to promoting investment for social impact groups
                    across the African Continent and Diaspora.
                </Typography>
            </div>
        </div>
    );
};

export default FounderStory;
