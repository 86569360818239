import { Box, Typography } from '@material-ui/core';
import React from 'react';
import ProjectsList from './ProjectsList';
import ProjectSuggestion from './ProjectSuggestion';

export default function VolunteerProjects() {
    return (
        <>
            <Box mt={6}>
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>My Projects</Box>
                </Typography>
            </Box>
            <ProjectsList />
            <Box mt={4}>
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>Project Suggestions</Box>
                </Typography>
            </Box>
            <ProjectSuggestion />
        </>
    );
}
