import React, { useEffect, useState, useCallback } from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardHeader,
    makeStyles,
} from '@material-ui/core';
import { ChatType, ReplyType } from 'types';
import ReactTimeago from 'react-timeago';
import firebase from 'utils/auth';
import { Chat, FiberNew } from '@material-ui/icons';
import ChatPopup from './ChatPopup';
import { useSnackbar } from 'notistack';
import { uniqBy } from 'lodash';

interface ChatCardProps {
    chatData: ChatType;
    isContact?: boolean;
    showChatCard?: boolean;
    closeVolunteerContact?: () => void;
}

const useStyles = makeStyles((theme) => ({
    body: {
        borderTop: '.1rem lightgray solid',
        borderBottom: '.1rem lightgray solid',
    },
    newIcon: {
        color: '#c22317',
    },
}));

const ChatCard = (props: ChatCardProps) => {
    const { showChatCard = true } = props;
    const [id, setId] = useState<string>('');
    const [userIndexInFirebaseChatroom, setUserIndexInFirebaseChatroom] =
        useState<number>(-1);
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [otherUserData, setOtherUserData] = useState<any>(null);
    const [replies, setReplies] = useState<Array<ReplyType>>([]);
    const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
    const [isChatDialogOpen, setIsChatDialogOpen] = useState<boolean>(false);
    const [hasNewReplies, setHasNewReplies] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    let updateData = useCallback(async () => {
        if (firebase.auth.currentUser && props.chatData) {
            let userPosition = props.chatData.participants.indexOf(
                firebase.auth.currentUser.uid,
            );
            if (userPosition !== -1) {
                let otherUserPosition = userPosition ? 0 : 1;
                let data = await firebase.getOtherUserData(
                    props.chatData.participants[otherUserPosition],
                );
                if (data.status === 'ok') {
                    setOtherUserData(data.payload);
                }
                setId(props.chatData.id);
                setUserIndexInFirebaseChatroom(userPosition);
            }
        }
        setIsChatLoading(false);
    }, [props.chatData]);

    useEffect(() => {
        //TODO: Need to work on this
        // if (id !== props.chatData.id) {
        updateData();
        firebase.db
            .collection('chat')
            .doc(props?.chatData?.id)
            .collection('replies')
            .orderBy('created_at')
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    let docData = change.doc.data();
                    docData.created_at = docData.created_at.toDate();
                    setReplies((pre) => {
                        let temp = [...pre];
                        temp.push({
                            ...docData,
                            id: change.doc.id,
                        } as ReplyType);
                        return uniqBy(temp, 'id');
                    });
                });
            });
        // return unsub;
        return () => {
            // unsub();
            setReplies([]);
        };
    }, [props?.chatData?.id, updateData, id]);

    useEffect(() => {
        if (id && userIndexInFirebaseChatroom >= 0 && props.chatData) {
            if (
                props.chatData.last_read[userIndexInFirebaseChatroom] !==
                props.chatData.last_reply
            ) {
                setHasNewReplies(true);
            } else {
                setHasNewReplies(false);
            }
        }
    }, [id, userIndexInFirebaseChatroom, props.chatData]);

    useEffect(() => {
        if (isChatDialogOpen && id && userIndexInFirebaseChatroom >= 0) {
            firebase.updateLastRead(id, userIndexInFirebaseChatroom);
        }
    }, [isChatDialogOpen, id, userIndexInFirebaseChatroom]);

    useEffect(() => {
        if (
            isChatDialogOpen &&
            replies &&
            replies.length &&
            id &&
            userIndexInFirebaseChatroom >= 0
        ) {
            if (
                replies[replies.length - 1].created_by !==
                firebase.auth.currentUser?.uid
            ) {
                firebase.updateLastRead(id, userIndexInFirebaseChatroom);
            }
        }
    }, [isChatDialogOpen, replies, id, userIndexInFirebaseChatroom]);

    const createNewReply = async (reply: string) => {
        setIsSendingMessage(true);
        const returnData = await firebase.createReply(
            reply,
            userIndexInFirebaseChatroom,
            id,
        );
        if (returnData.status === 'error') {
            enqueueSnackbar('network issue!', {
                variant: 'error',
                autoHideDuration: 1000,
            });
        }
        setIsSendingMessage(false);
    };

    const deleteMessage = async () => {
        const messageId = props.chatData.id;
        const returnData = await firebase.deleteMessage(messageId);
        if (returnData.status === 'error') {
            enqueueSnackbar('Something went wrong. Please try again!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        } else {
            enqueueSnackbar(returnData.payload, {
                variant: 'success',
                autoHideDuration: 2000,
            });
            props.closeVolunteerContact?.();
        }
    };

    return (
        <Card className="nf_card g_mb1">
            {!isChatLoading && (
                <ChatPopup
                    replies={replies}
                    isOpen={isChatDialogOpen || !!props.isContact}
                    otherUser={otherUserData}
                    deleteMessage={deleteMessage}
                    onCreateReply={createNewReply}
                    isSendingMessage={isSendingMessage}
                    onClose={() => {
                        setIsChatDialogOpen(false);
                        setIsChatLoading(false);
                        props.closeVolunteerContact?.();
                    }}
                    userId={firebase.auth.currentUser?.uid ?? ''}
                />
            )}
            {otherUserData && replies.length > 0 && showChatCard && (
                <>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={otherUserData.userAvatar}
                                aria-label="user"
                            />
                        }
                        title={<>{otherUserData.userName}</>}
                        subheader={
                            <ReactTimeago date={props.chatData.created_at} />
                        }
                    />
                    <CardActions>
                        <Button
                            startIcon={<Chat color="action" />}
                            endIcon={
                                hasNewReplies ? (
                                    <FiberNew className={classes.newIcon} />
                                ) : null
                            }
                            className="nf_card_actionbutton"
                            onClick={() => setIsChatDialogOpen(true)}
                        >
                            Open
                        </Button>
                    </CardActions>
                </>
            )}
        </Card>
    );
};

export default ChatCard;
