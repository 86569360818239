import useAlgolia from 'hooks/useAlgolia';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ProjectType } from 'types';
import { UserContext } from 'contexts/UserContext';
import ProjectCard from 'components/ProjectCard/ProjectCard';
import { useSnackbar } from 'notistack';
import useJoinProject from 'hooks/useJoinProject';

export default function ProjectSuggestion() {
    const { projectsIndex } = useAlgolia();
    const { enqueueSnackbar } = useSnackbar();
    const { userData, isChecking } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [suggestions, setSuggestions] = useState<ProjectType[]>([]);

    const { handleJoinProject } = useJoinProject(userData, enqueueSnackbar);
    const fetchSuggestions = useCallback(async () => {
        if (userData) {
            let filters = '';
            filters += `activity_duration_per_week <= ${userData.timing} AND `;
            if (userData.work_type === 3) {
                filters += `(activity_type = 1 OR activity_type = 2)`;
            } else {
                filters += `activity_type = ${userData.work_type}`;
            }
            if (userData.skills && userData.skills?.length > 0) {
                filters += ' AND (';
                filters += userData.skills
                    .map((skill: string) => `categories:"${skill}"`)
                    .join(' OR ');
                filters += ') ';
                setIsLoading(true);
                const result = await projectsIndex.search('', {
                    filters,
                    facetFilters: `team_member_ids:-${userData.id}`,
                    hitsPerPage: 6,
                });
                setSuggestions(result.hits as any);
            }
            setIsLoading(false);
        }
    }, [projectsIndex, userData]);

    useEffect(() => {
        if (!isChecking && userData) {
            fetchSuggestions();
        }
    }, [userData, isChecking, fetchSuggestions]);

    const handleJoinProjectRequest = useCallback(
        async (projectId: string, organizationId: string) => {
            debugger;
            await handleJoinProject(projectId, organizationId);
        },
        [handleJoinProject],
    );

    // const requestStatusLabel = getProjectRequestStatusLabel(
    //     userData?.requestStatus ?? [],
    //     userData?.id!,
    // );
    // console.log(
    //     '🚀 ~ file: ProjectSuggestion.tsx:64 ~ ProjectSuggestion ~ requestStatusLabel:',
    //     requestStatusLabel,
    // );

    // const handleJoinProject = useCallback(
    //     async (projectId: string, organizationId: string) => {
    //         if (userData?.id) {
    //             const response = await firebase.createVolunteerRequestToJoinOrg(
    //                 projectId,
    //                 organizationId,
    //                 userData.id,
    //                 userData?.first_name + ' ' + userData?.last_name,
    //             );
    //             if (response.status === 200) {
    //                 enqueueSnackbar(
    //                     'Request successfully sent to the organization!',
    //                     {
    //                         variant: 'success',
    //                         autoHideDuration: 3000,
    //                         anchorOrigin: {
    //                             vertical: 'top',
    //                             horizontal: 'right',
    //                         },
    //                     },
    //                 );
    //             } else {
    //                 enqueueSnackbar(response.error, {
    //                     variant: 'error',
    //                     autoHideDuration: 3000,
    //                     anchorOrigin: {
    //                         vertical: 'top',
    //                         horizontal: 'right',
    //                     },
    //                 });
    //             }
    //         }
    //     },
    //     [
    //         userData?.id,
    //         userData?.first_name,
    //         userData?.last_name,
    //         enqueueSnackbar,
    //     ],
    // );
    return (
        <Box>
            {isChecking ? (
                <CircularProgress />
            ) : (
                <Box mt={2}>
                    {isLoading && (
                        <Box mx="auto" mt={4}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!isLoading &&
                        userData &&
                        suggestions.map((project) => (
                            <ProjectCard
                                key={project.objectID}
                                projectId={project.objectID!}
                                heading={project.name}
                                subHeading={project.causes.join(', ')}
                                groupName={project.group_name}
                                startTime={project.schedule_start}
                                endTime={project.schedule_end}
                                duration={project.activity_duration_per_week}
                                volunteerLocation={project.activity_type}
                                // teamHeadline={'Join the team!'}
                                team={project.team_members}
                                isSuggestion={true}
                                handleJoinProject={handleJoinProjectRequest}
                                organizationId={project.organization_id}
                                requestStatus={userData.requestStatus}
                            />
                        ))}
                    {!isLoading && suggestions.length === 0 && (
                        <Typography>
                            No project found. Make sure to update your
                            Preferences in the "My Information" tab.
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
}
