import React, { useEffect, useState } from 'react';
import { ProjectType } from 'types';
import { CircularProgress } from '@material-ui/core';
import FeaturedCard from '../FeaturedCard/FeaturedCard';
import firebase from 'utils/auth';
import './FeaturedList.scss';

const FeaturedProjects = () => {
    const [isActive, setIsActive] = useState(true);
    const [list, setList] = useState<Array<ProjectType>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getFeaturedOrganizations = async () => {
            let { status, payload } = await firebase.getFeaturedProjects();
            setIsLoading(false);
            if (status === 'ok') {
                if (isActive) {
                    setList(payload as []);
                }
            } else {
                // react to error
            }
        };
        getFeaturedOrganizations();

        return () => {
            setIsActive(false);
        };
    }, [isActive]);

    return (
        <div className="hw_organization_list">
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    {list.map((item: ProjectType) => (
                        <FeaturedCard
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            description={item.description}
                            documentId={item.id}
                            //TODO: Need to update location address here
                            location={item.location?.address?.label?.toString()}
                            image_url={item.image_url}
                            skills={item.categories}
                            requirements={item.requirements}
                            variant="project"
                        />
                    ))}
                    {/* <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width={'100%'}
                        padding="10px"
                    >
                        <Button
                            disabled={isChecking}
                            onClick={handleSeeFullList}
                            className="g_primary_btn"
                            color="primary"
                            variant="contained"
                        >
                            See Full List
                        </Button>
                    </Box> */}
                </>
            )}
        </div>
    );
};

export default FeaturedProjects;
