import {
    Box,
    Button,
    Container,
    FormControl,
    NativeSelect,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import './HowItWorks.scss';
import FeaturedProjects from '../../components/FeaturedList/FeaturedProjects';
import firebase from '../../utils/auth';

import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'App';
import { UserContext } from 'contexts/UserContext';
import Alert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from 'const';
import { Link } from 'react-router-dom';
import useAlgolia from 'hooks/useAlgolia';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
const organizationsIndex = searchClient.initIndex('organizations');

export const HowWorksVolunteer = () => {
    const { projectsIndex } = useAlgolia();
    const [citySearch, setCitySearch] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [projectSearch, setProjectSearch] = useState('');
    const [cityOptions, setCityOptions] = useState<any>([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { isChecking, isLoggedIn } = useContext(UserContext);
    const [process, setProcess] = useState('Individual Partners');
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState<any>([]);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    const history = useHistory();

    const goToSearchPage = () => {
        history.push(AppRoutes.volunteerOpportunities, {
            search: projectSearch,
            city: citySearch,
        });
    };

    const handleSeeFullList = () => {
        if (isLoggedIn) {
            history.push(AppRoutes.volunteerOpportunities);
        } else {
            setShowSnackbar(true);
        }
    };

    const getCities = useCallback((search: string) => {
        organizationsIndex
            .searchForFacetValues('city', search)
            .then((result) => {
                setCityOptions(
                    result.facetHits.map((result: any) => {
                        return {
                            label: result.value,
                            value: result.value,
                            highlighted: result.highlighted,
                        };
                    }),
                );
            });
    }, []);

    useEffect(() => {
        if (projectSearch) {
            projectsIndex
                .search(projectSearch)
                .then((data) => {
                    setSuggestions(
                        data.hits.map((result: any) => {
                            return {
                                label: result.name,
                                value: result.name,
                                highlighted: result._highlightResult.name.value,
                            };
                        }),
                    );
                })
                .catch((error) => {
                    console.error('Error fetching search results:', error);
                });
        } else {
            setValue('');
            setSuggestions([]);
        }
    }, [projectSearch, projectsIndex]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (citySearch) getCities(citySearch);
    }, [getCities, citySearch]);

    const handleCityAutoCompleteChange = (
        e: React.ChangeEvent<object>,
        data: any,
    ) => {
        setCitySearch(data?.value);
        setSelectedCity(data);
        !data && setCityOptions([]);
    };

    const handleChange = (e: React.ChangeEvent<object>, data: any) => {
        setValue(data);
        setProjectSearch(data?.value);
    };

    return (
        <div className="hwv_root">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see full list of projects
                </Alert>
            </Snackbar>
            {/* <div className="g_profile_banner">
                <img src={logo} alt="logo" />
            </div> */}
            <div className="hwv_body g_wfull g_whitebg">
                <Container maxWidth="md">
                    <div className="g_row g_tspacer3 g_bspacer2">
                        <Typography variant="h5">How it Works for</Typography>
                        <FormControl>
                            <NativeSelect
                                value={process}
                                onChange={(e) => setProcess(e.target.value)}
                                name="age"
                                className="hwv_typeselector"
                                inputProps={{ 'aria-label': 'age' }}
                                variant="outlined"
                            >
                                <option value={'Individual Partners'}>
                                    Individual Partners
                                </option>
                                <option value={'Academic Institutions'}>
                                    Academic Institutions
                                </option>
                                <option value={'Corporate Partners'}>
                                    Corporate Partners
                                </option>
                            </NativeSelect>
                        </FormControl>
                    </div>
                    <div className="hw_process">
                        {process === 'Individual Partners' && (
                            <>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Sign-Up
                                        </Typography>
                                    </div>
                                    <Typography>
                                        {' '}
                                        Answer our call to{' '}
                                        <Link
                                            className=""
                                            to={AppRoutes.Signup}
                                        >
                                            “Join Now”
                                        </Link>{' '}
                                        and tell us about you! We want to learn
                                        about your background, skills,
                                        interests, and what goals you have for
                                        the future.
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Apply
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Once we have reviewed and approved your
                                        application, you will then create a
                                        custom profile page. You will be able to
                                        customize your page—adding any
                                        information that will help us direct
                                        great projects your way!
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Work Together
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Once we have vetted your application,
                                        you will have the opportunity to create
                                        your profile page. You will be able to
                                        customize your page, adding information
                                        that we will use to direct great
                                        projects your way!
                                    </Typography>
                                </div>
                            </>
                        )}
                        {process === 'Academic Institutions' && (
                            <>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Sign-Up
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Answer our call to{' '}
                                        <Link
                                            className=""
                                            to={AppRoutes.Signup}
                                        >
                                            “Join Now”
                                        </Link>{' '}
                                        and tell us about your institution! We
                                        want to learn about your collective
                                        goals, the skills and interest of your
                                        students and staff
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Apply
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Once we have reviewed and approved your
                                        application, you will then create a
                                        custom profile page. You will be able to
                                        customize your page—adding any
                                        information that will help us direct
                                        great projects your way!
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Work Together
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Find your perfect match. You will be
                                        connected to organizations who have a
                                        need for your skills.
                                    </Typography>
                                </div>
                            </>
                        )}
                        {process === 'Corporate Partners' && (
                            <>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Sign-Up
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Answer our call to{' '}
                                        <Link
                                            className=""
                                            to={AppRoutes.Signup}
                                        >
                                            “Join Now”
                                        </Link>{' '}
                                        and tell us about your corporation! We
                                        want to learn about your collective
                                        goals, the skills and interest of your
                                        employees.{' '}
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Apply
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Once we have reviewed and approved your
                                        application, you will then create a
                                        custom profile page. You will be able to
                                        customize your page—adding any
                                        information that will help us direct
                                        great projects your way!{' '}
                                    </Typography>
                                </div>
                                <div className="hw_process_section">
                                    <div className="hw_process_section_title">
                                        <Typography variant="h5">
                                            Work Together
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Find your perfect match. You will be
                                        connected to organizations who have a
                                        need for the skills your institutions
                                        provides.
                                    </Typography>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="hw_searcharea">
                        <div className="g_mb1">
                            <Typography variant="h6">
                                Search for a Project
                            </Typography>
                        </div>
                        <div className="hw_searcharea_items">
                            {/* <div className="hw_searcharea_input">
                                <TextField
                                    value={projectSearch}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setProjectSearch(e.target.value)}
                                    fullWidth
                                    placeholder="Search by keyword"
                                    size="small"
                                    variant="outlined"
                                />
                            </div> */}
                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="keyword-box-demo"
                                    value={value}
                                    options={suggestions}
                                    getOptionLabel={(option: any) => {
                                        return option.value || '';
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <div
                                                className="hw_sc_suggestion"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.highlighted,
                                                }}
                                            />
                                        );
                                    }}
                                    noOptionsText={'No keyword found'}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                e.persist();
                                                clearTimeout(+timerId!);
                                                const id = setTimeout(() => {
                                                    setProjectSearch(
                                                        e.target.value,
                                                    );
                                                }, 500);
                                                setTimerId(id);
                                            }}
                                            label="Search by keyword"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>

                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="city-box-demo"
                                    options={cityOptions}
                                    value={selectedCity}
                                    onChange={handleCityAutoCompleteChange}
                                    getOptionLabel={(option: any) =>
                                        option.value || ''
                                    }
                                    renderOption={(option: any) => {
                                        return (
                                            <div
                                                className="hw_sc_suggestion"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.highlighted,
                                                }}
                                            />
                                        );
                                    }}
                                    noOptionsText={'No city found'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => setCitySearch(e.target.value)}
                                            label="Search by city"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={goToSearchPage}
                                className="g_primary_btn"
                                disabled={isChecking}
                            >
                                Search
                            </Button>
                        </div>
                    </div>

                    <div className="g_row_center g_mt4 g_mb1">
                        <Typography variant="h4">Featured Projects</Typography>
                    </div>
                    <div className="hw_organization_list">
                        <FeaturedProjects />
                    </div>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                    >
                        <Button
                            disabled={isChecking}
                            onClick={handleSeeFullList}
                            style={{ textDecoration: 'underline' }}
                        >
                            See Full List &gt;&gt;
                        </Button>
                    </Box>
                    <div className="g_row_center g_mt2">
                        <Typography variant="h5">
                            Connect Your Skills to Those Who Need it the Most
                        </Typography>
                    </div>
                    <div className="g_row_center g_mt2 g_mb1">
                        {!firebase.isAuthenticated() && (
                            <div className="hw_selected_button">
                                <Button
                                    className="g_primary_btn"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        history.push(AppRoutes.Signup);
                                    }}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        )}
                        <div className="hw_selected_button">
                            <Button
                                fullWidth
                                color="default"
                                variant="outlined"
                            >
                                Contact Us
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
