import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { SignupContext } from '../../contexts/SignupContext';
import { InputLabel, Snackbar, TextareaAutosize } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AboutOrganization: React.FC = () => {
    const { step2Data, setStep2Data, setStep2File } = useContext(SignupContext);
    const [fileName, setFileName] = useState(
        'Please upload any supporting information',
    );
    const [snackData, setSnackData] = useState({
        show: false,
        type: 'warning',
        msg: '',
    });

    // const addReference = () => {
    //     setStep2Data((pre: any) => [
    //         ...pre,
    //         {
    //             profession: '',
    //             license_number: '',
    //             email: '',
    //             linkedin_profile: '',
    //             password: '',
    //         },
    //     ]);
    // };

    // const removeReference = () => {
    //     setStep2Data((pre: any) => {
    //         if (pre.length > 1) {
    //             let temp = [...pre];
    //             temp.pop();
    //             return temp;
    //         } else {
    //             return pre;
    //         }
    //     });
    // };

    const valueChanged = <T extends HTMLInputElement | HTMLTextAreaElement>(
        event: React.ChangeEvent<T>,
        index: number = 0,
    ) => {
        event.persist();
        setStep2Data((pre: any) => {
            let temp = [...pre];
            temp[index] = {
                ...temp[index],
                [event.target.name]: event.target.value,
            };
            return temp;
        });
    };

    const fileAdded = (event: any) => {
        // console.log(event.target.file);
        // console.log(event.target.files[0].type);
        let file = event.target.files[0];
        // let fType = event.target.files[0].type;
        if (
            file.name.endsWith('.pdf') ||
            file.name.endsWith('.doc') ||
            file.name.endsWith('.docx') ||
            file.name.endsWith('.png') ||
            file.name.endsWith('.jpg') ||
            file.name.endsWith('.jpeg')
        ) {
            setSnackData({
                show: true,
                type: 'success',
                msg: 'File selected!',
            });
            setFileName(file.name);
            //TODO: Need to check
            // const name =
            //     'new-name-here' +
            //     file.name.substring(file.name.lastIndexOf('.'));
            file = new File([file], Date.now() + '-' + file.name, {
                type: file.type,
            });
            setStep2File(file);
        } else {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'File type not supported!',
            });
        }
    };

    const handleSnackClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackData((pre: any) => ({
            ...pre,
            show: false,
        }));
    };

    return (
        <Container className="root">
            <Snackbar
                open={snackData.show}
                autoHideDuration={4000}
                onClose={handleSnackClose}
            >
                <Alert onClose={handleSnackClose} severity={snackData.type}>
                    {snackData.msg}
                </Alert>
            </Snackbar>
            <div>
                <form noValidate autoComplete="off">
                    {step2Data &&
                        step2Data.map((rowData: any, index: number) => {
                            return (
                                <div key={index}>
                                    <div className="row row_centered">
                                        <div style={{ width: '80%' }}>
                                            <h3>About Your Organization</h3>

                                            <TextareaAutosize
                                                rowsMax={15}
                                                className="statement"
                                                aria-label="maximum height"
                                                placeholder="Maximum 1000 characters"
                                                name="about_organization"
                                                value={
                                                    step2Data.about_organization
                                                }
                                                onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row row_centered">
                                        <div style={{ width: '80%' }}>
                                            <h3>Mission and goals</h3>

                                            <TextareaAutosize
                                                rowsMax={15}
                                                className="statement"
                                                aria-label="maximum height"
                                                placeholder="Maximum 1000 characters"
                                                name="mission_and_goals"
                                                value={
                                                    step2Data.mission_and_goals
                                                }
                                                onChange={valueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="row row_centered">
                                        <div
                                            style={{
                                                width: '80%',
                                                marginTop: '30px',
                                            }}
                                        >
                                            <InputLabel
                                                style={{ lineHeight: '18px' }}
                                            >
                                                Please provide a link to
                                                organization's website. If your
                                                page doesn't have a website
                                                please share relevant social
                                                media links
                                            </InputLabel>

                                            <TextField
                                                id="fullName"
                                                name={'website_link'}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) => valueChanged(e)}
                                                value={
                                                    step2Data[index]
                                                        .website_link
                                                }
                                                type="text"
                                                label="Website Link*"
                                                variant="outlined"
                                                className="form-field"
                                                style={{ marginLeft: 0 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="cta-container">
                                        <p className="file_name">{fileName}</p>
                                    </div>
                                    <div className="cta-container">
                                        <Button
                                            variant="contained"
                                            component="label"
                                            color="secondary"
                                        >
                                            Upload
                                            <input
                                                type="file"
                                                hidden
                                                onChange={fileAdded}
                                            />
                                        </Button>
                                    </div>
                                    {/* <div className="row">
                                        <div className="full-width"></div>
                                    </div> */}
                                </div>
                            );
                        })}
                    {/* <div className="ref-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={removeReference}
                        >
                            - Remove Reference
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addReference}
                        >
                            + Add Reference
                        </Button>
                    </div> */}
                    {/* <div className="cta-container">
            <Button variant="contained" color="secondary">
              Save & Continue
            </Button>
          </div> */}
                </form>
            </div>
        </Container>
    );
};

export default AboutOrganization;
